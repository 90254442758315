import { Dispatch, FC, SetStateAction } from 'react';

import { useNavigate } from 'react-router-dom';
import { get1CInstanceWithIdRoute } from 'routes/settings/list';

import { CInstancesResponseType } from 'services/1c-instances/cInstancesService.types';

import { Table as TableLib, TableProps as TablePropsLib } from 'gazprom-ui-lib';

import { CInstanceType } from 'types/cInstances.types';

import { RequestBodyType } from '../../settings1cInstances.utils';
import s from './Table.module.scss';
import useColumns from './useColumns.hook';

interface TableProps {
  dataSource?: CInstancesResponseType;
  loading: boolean;
  setCInstanceToDelete: Dispatch<SetStateAction<string[]>>;
  onChange: TablePropsLib<CInstanceType>['onChange'];
  requestBody: RequestBodyType;
}

const Table: FC<TableProps> = (props) => {
  const { requestBody, onChange, dataSource, loading, setCInstanceToDelete } = props;

  const navigate = useNavigate();

  const columns = useColumns({ setCInstanceToDelete });

  const onRow = (row: CInstanceType) => ({
    onClick: () => navigate(get1CInstanceWithIdRoute(row.id), { state: { requestBody } }),
  });

  const pagination = {
    defaultCurrent: requestBody.page,
    defaultPageSize: requestBody.size,
    current: requestBody.page,
    total: dataSource?.totalCount,
  };

  return (
    <TableLib
      rowKey="id"
      className={s.table}
      columns={columns}
      dataSource={dataSource?.items}
      pagination={pagination}
      loading={loading}
      onChange={onChange}
      onRow={onRow}
    />
  );
};

export default Table;
