import {
  GetFreeStatsByIdResponseType,
  GetSurveysListResponseType,
} from './surveysApiService.types';
import {
  EmployeeGroup,
  MappedReceivers,
  ReceiversType,
  SurveyType,
  SurveysListInfo,
  SurveysListOptions,
} from 'types/surveys.types';

export const mapSurveysList = (params: GetSurveysListResponseType[]) => {
  return params.reduce(
    (acc, { id, revision_id, settings }) => {
      return {
        surveysInfo: {
          ...acc.surveysInfo,
          [id]: { anketologId: id.toString(), revisionId: revision_id, name: settings.name },
        },
        options: [...acc.options, { value: id.toString(), label: settings.name }],
      };
    },
    {
      surveysInfo: {} as Record<string, SurveysListInfo>,
      options: [] as SurveysListOptions[],
    },
  );
};

export const mapEmployeeGroups = (params: EmployeeGroup[]) => {
  return params.map(({ id, title }) => ({ value: id, label: title }));
};

export const mapSurvey = (param: SurveyType) => {
  const { name, revisionId, anketologId, receivers, ...rest } = param;

  const mappedReceivers = (receivers as ReceiversType[]).reduce(
    (acc: MappedReceivers, receiver: ReceiversType) => {
      const type = receiver.receiverType;
      if (type === 'EMPLOYEE_GROUP') {
        acc[type].push(...receiver.receivers.map(({ id }) => id));
      }
      if (type === 'EMPLOYER') {
        acc[type].push(...receiver.receivers.map(({ id }) => id));
      }

      return acc;
    },
    { EMPLOYEE_GROUP: [], EMPLOYER: [] } as MappedReceivers,
  );

  return {
    info: {
      name,
      revisionId,
      anketologId,
    },
    receivers: {
      ...mappedReceivers,
    },
    ...rest,
  };
};

export const mapFreetable = ({ items, ...rest }: GetFreeStatsByIdResponseType) => {
  const result = items.map(({ employeeName, employerName, positionName, ...rest }) => ({
    preson: { firstName: employeeName, department: employerName, position: positionName },
    ...rest,
  }));

  return { items: result, ...rest };
};
