import TopManagementForms from 'pages/top-management/top-management-forms';
import TopManagementFormsDetails from 'pages/top-management/top-management-forms/top-management-forms-details';
import TopManagementPositionDirectory from 'pages/top-management/top-management-position-directory';
import TopManagementPositionDirectoryDetails from 'pages/top-management/top-management-position-directory/top-management-position-directory-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  TOP_MANAGEMENT_FORMS_DETAILS_ROUTE,
  TOP_MANAGEMENT_FORMS_ROUTE,
  TOP_MANAGEMENT_POSITIONS_DIRECTORY_DETAILS_ROUTE,
  TOP_MANAGEMENT_POSITIONS_DIRECTORY_ROUTE,
} from './list';

export const topManagementRoutes: RouteConfig[] = [
  {
    path: TOP_MANAGEMENT_POSITIONS_DIRECTORY_ROUTE,
    element: <TopManagementPositionDirectory />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: TOP_MANAGEMENT_POSITIONS_DIRECTORY_DETAILS_ROUTE,
    element: <TopManagementPositionDirectoryDetails />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA, GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_MOBILE],
  },
  {
    path: TOP_MANAGEMENT_FORMS_ROUTE,
    element: <TopManagementForms />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA],
  },
  {
    path: TOP_MANAGEMENT_FORMS_DETAILS_ROUTE,
    element: <TopManagementFormsDetails />,
    roles: [GLOBAL_ROLES.MODERATOR_TOPS_ANKETA],
  },
];
