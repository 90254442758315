import { Dispatch, FC, SetStateAction, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeToNumbers } from 'utils/formRules';

import { useLazyRequestAuthorizationQuery } from 'services/authorization/authorizationApiService';
import { RequestAuthorizationResponseType } from 'services/authorization/authorizationApiService.types';

import { Button, Flex, Form, Input, Typography, handleRequest } from 'gazprom-ui-lib';

import {
  AuthorizationErrorCode,
  TELEGRAM_AUTHORIZATION_FORM_ENUM,
  TELEGRAM_AUTHORIZATION_PHONE_FORM_NAME,
  TelegramAuthorizationPhoneFormResponse,
  getAuthId,
  getAuthorizationErrorTitle,
} from '../../settingsTelegramAuthorization.utils';
import s from './AuthorizationRequestForm.module.scss';

interface AuthorizationRequestFormProps {
  setAuthId: Dispatch<SetStateAction<string>>;
  setIsPhoneConfirmed: Dispatch<SetStateAction<boolean>>;
}

const AuthorizationRequestForm: FC<AuthorizationRequestFormProps> = (props) => {
  const { setAuthId, setIsPhoneConfirmed } = props;

  const [requestAuthError, setRequestAuthError] = useState<string>('');

  const clearAuthorizationError = () => {
    setRequestAuthError('');
  };

  const [requestAuthorization, { isLoading: isRequestAuthorizationLoading }] =
    useLazyRequestAuthorizationQuery();

  const handleConfirmPhone = (formResponse: TelegramAuthorizationPhoneFormResponse) => {
    const onSuccess = (data?: RequestAuthorizationResponseType) => {
      if (data) {
        const { message, telegramError } = data;
        if (telegramError) {
          setRequestAuthError(getAuthorizationErrorTitle(message as AuthorizationErrorCode));
        } else {
          setAuthId(getAuthId(message));
          setIsPhoneConfirmed(true);
        }
      }
    };

    requestAuthorization({
      phoneNumber: `7${formResponse[TELEGRAM_AUTHORIZATION_FORM_ENUM.PHONE_NUMBER]}`,
    }).then(handleRequest({ onSuccess }));
  };

  return (
    <Form name={TELEGRAM_AUTHORIZATION_PHONE_FORM_NAME} onFinish={handleConfirmPhone}>
      <Flex vertical gap="8">
        <Typography.Text type="secondary">{t('telegram_enter_by_phone')}</Typography.Text>
        <Form.Item
          normalize={normalizeToNumbers}
          name={TELEGRAM_AUTHORIZATION_FORM_ENUM.PHONE_NUMBER}
          rules={[formRules.required, { len: 10 }]}>
          <Input
            onChange={clearAuthorizationError}
            maxLength={10}
            className={s.input}
            allowClear
            placeholder={t('common_phone_number')}
            addonBefore="+7"
            type="phone"
          />
        </Form.Item>
        {requestAuthError && <Typography.Text type="danger">{requestAuthError}</Typography.Text>}
      </Flex>
      <Flex className={s.actions}>
        <Button loading={isRequestAuthorizationLoading} htmlType="submit" leftIcon="key">
          {t('common_send_code')}
        </Button>
      </Flex>
    </Form>
  );
};

export default AuthorizationRequestForm;
