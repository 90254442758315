import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { Icon, Input, InputProps } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import WithLoader from 'containers/wrappers/with-loader';

import { TMappedFeedBacks } from 'types/thankYou.types';

import ThankYouFilters from './thank-you-filters';
import ThankYouTable from './thank-you-table';
import {
  DEFAULT_REQUEST_BODY,
  RequestBody,
  ThankYouFiltersType,
  mapFeedBacks,
} from './thank-you.utils';
import useGetFeedbacks from './useGetFeedbacks.hook';

const ThankYou = () => {
  const [mappedFeedbacks, setMappedFeedbacks] = useState<TMappedFeedBacks>([]);
  const [requestBody, setRequestBody] = useState<RequestBody>(DEFAULT_REQUEST_BODY);
  const [filters, setFilters] = useState<ThankYouFiltersType>(null);
  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody, 150);
  const { feedbacks, isFetching, isLoading } = useGetFeedbacks({ debouncedRequestBody, filters });

  const onFiltersSubmit = (values: ThankYouFiltersType) => {
    setFilters(values);
    setRequestBody((prevState) => ({ ...prevState, page: DEFAULT_REQUEST_BODY.page }));
  };

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      text: e.target.value,
      page: DEFAULT_REQUEST_BODY.page,
    }));
  };

  const filterActions = (
    // TODO Перенести компонент ThankYouActions в это место по аналогии с блоком новостей
    <Input
      size="small"
      prefix={<Icon name="search" />}
      placeholder={t('common_search')}
      allowClear
      value={requestBody.text}
      onChange={handleSearchChange}
    />
  );

  useEffect(() => {
    const currentFeedbacks = feedbacks?.feedbacks.length ? feedbacks.feedbacks : [];
    setMappedFeedbacks(mapFeedBacks(currentFeedbacks));
  }, [feedbacks]);

  return (
    <WithLoader isLoading={isLoading}>
      <ContentHeader>
        <ContentHeader.Title total={feedbacks?.totalCount}>
          {t('thank_you_title')}
        </ContentHeader.Title>
      </ContentHeader>
      <ThankYouFilters
        actions={filterActions}
        filters={filters}
        onFiltersSubmit={onFiltersSubmit}
      />
      <ThankYouTable
        loading={isFetching}
        dataSource={mappedFeedbacks}
        paginationConfig={{
          defaultCurrent: requestBody.page,
          defaultPageSize: requestBody.size,
          current: requestBody.page,
          total: feedbacks?.totalCount,
        }}
        onChange={handleTableChange(setRequestBody)}
        filters={{ ...filters, text: requestBody.text }}
      />
    </WithLoader>
  );
};

export default ThankYou;
