import { useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_SINGS_ROUTE } from 'routes/documents/list';
import { SIGN_ROUTE } from 'routes/sign/list';
import { t } from 'tools/i18n';
import { Trans } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { useGetTaskAssignmentByIdQuery } from 'services/1c/cApiService';

import { Button, Flex, Icon, StepsProps, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';
import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { getComponentsParams } from 'pages/documents/documents-view/documents-view-files/documentsViewFiles.utils';
import { renderSign } from 'pages/documents/documents.utils';

import { CurrentKedoStatusType } from 'types/documents.types';
import { FileType } from 'types/file.types';
import { TASK_ASSIGNMENT_TYPE_ENUM } from 'types/taskAssignment.types';

import s from './DocumentsSignsDetails.module.scss';
import DocumentsSignsViewRejectModal from './documents-signs-view-reject-modal';
import DocumentsViewPerson from './documents-view-person';
import { getFileUrl } from './documentsSignsDetails.utils';

const DocumentsSignsDetails = () => {
  const { id, executorId } = useParams();
  const navigate = useNavigate();

  const [rejectModalVisible, setRejectModalVisible] = useState(false);

  const {
    data: taskData,
    isLoading,
    isError,
  } = useGetTaskAssignmentByIdQuery({
    id: id!,
    executorId: executorId!,
  });

  const description = (
    <>
      {`${t('common_created')}: `}
      <Flex gap="4" align="center">
        <Icon name="calendar" />
        <Typography.Text type="secondary">
          {dayjs.utc(taskData?.createdAt).local().format('DD MMMM YYYY, HH:mm')}
        </Typography.Text>
      </Flex>
    </>
  );

  const renderFile = (file: FileType) => {
    const { fileKey, fileName, signs, type, dateDoc, numberDocPrint } = file;
    const isSigned = !!signs?.length;
    const isOrderType = type === 'ORDER' || type === 'CONTRACT';
    const isDocNumAndDate = isOrderType && numberDocPrint && dateDoc;
    const fileDownloadUrl =
      getFileUrl(taskData?.id ?? '', fileKey) + (isSigned ? '&full=true' : '');

    const renderSigns = () => {
      const signerId = taskData?.executor.id ?? '';

      if (isOrderType) {
        return signs?.filter(({ key }) => key.includes(signerId))?.map(renderSign);
      }

      return signs?.map(renderSign);
    };

    const handleNavigate = () => {
      if (fileKey)
        navigate(SIGN_ROUTE, {
          state: {
            fileKey,
            assignmentId: taskData?.id,
            executorId: taskData?.executor.id,
          },
        });
    };

    const handleReject = () => {
      setRejectModalVisible(true);
    };

    const isRefuseUnavailable = taskData?.type === TASK_ASSIGNMENT_TYPE_ENUM.SIGN_HEAD_NO_REFUSE;

    const personProps = getComponentsParams({
      rejected: taskData?.status.code === 'REJECTED' || taskData?.status.code === 'REVISION',
      signed: taskData?.status.code === 'APPROVED',
      onClick: handleNavigate,
      onReject: !isRefuseUnavailable ? handleReject : undefined,
    });

    return (
      <div key={fileKey}>
        <div className={cn(s.download, { [s.signed]: isSigned })}>
          <div>
            <Icon name="image" className={s.icon} />
            <div className={s.fileDescription}>
              <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
                {fileName}
              </Typography.Text>
              {isDocNumAndDate && (
                <div>
                  <Trans
                    i18nKey="sign_document_number"
                    values={{
                      docNum: numberDocPrint,
                      date: dayjs(dateDoc).local().format('DD.MM.YYYY'),
                    }}>
                    <Typography.Text size="12" type="secondary" />
                    <Typography.Text size="12" type="secondary" />
                  </Trans>
                </div>
              )}
              {renderSigns()}
            </div>
          </div>
          <div>
            {isSigned && <Icon name="okFilled" color="var(--color-success)" />}
            <button
              className={s.downloadButton}
              onClick={() => downloadHandler({ url: fileDownloadUrl, fileName })}>
              <Icon name="download" color="var(--color-primary-6)" />
            </button>
          </div>
        </div>
        {isOrderType && (
          <div className={s.personsContainer}>
            <DocumentsViewPerson
              firstName={taskData?.executor?.person.firstName ?? ''}
              lastName={taskData?.executor?.person.lastName ?? ''}
              {...personProps}
            />
          </div>
        )}
      </div>
    );
  };
  const currentKedoStatusImitation = {
    comment: null,
    createdAt: null,
    description: taskData?.status?.description,
    state: taskData?.status?.state,
    status: taskData?.status?.code,
    title: taskData?.status?.name,
    titleAlt: null,
  } as CurrentKedoStatusType;

  const handleClose = () => {
    navigate(DOCUMENTS_SINGS_ROUTE);
  };

  const handleModalClose = () => setRejectModalVisible(false);

  const showTaskHistory = taskData?.kedoTask || taskData?.workTour;
  const taskHistory =
    showTaskHistory && (taskData?.kedoTask?.history ?? taskData?.workTour?.history);

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={-1} description={description}>
          {t('common_view_document')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <KedoStatusTag currentKedoStatus={currentKedoStatusImitation} size="30" />
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <DocumentView isError={isError}>
          <DocumentView.Content>
            <Flex vertical gap="24">
              <DocumentView.Employee employee={taskData?.employee} />
              <Flex vertical gap="8">
                <Typography.Title level={5}>{t('common_document')}</Typography.Title>
                <Typography.Text size="14" type="secondary">
                  {taskData?.title}
                </Typography.Text>
              </Flex>
              <div className={s.fileWrapper}>
                {(
                  taskData?.kedoTask?.files?.filter((file) => file.type === 'ORDER') ??
                  taskData?.kedoDocument?.files
                )?.map(renderFile)}
              </div>
            </Flex>
            {showTaskHistory && (
              <DocumentView.History historyItems={taskHistory as StepsProps['items']} />
            )}
          </DocumentView.Content>
          <Button type="secondary" className={s.closeButton} onClick={handleClose}>
            {t('common_close')}
          </Button>
        </DocumentView>
        <DocumentsSignsViewRejectModal
          open={rejectModalVisible}
          taskId={id}
          employeeId={executorId}
          onCancel={handleModalClose}
        />
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsSignsDetails;
