import { FC } from 'react';

import { Table as TableLib } from 'gazprom-ui-lib';

import { BusinessTripApprovalItemType } from 'types/businessTrip.types';

import s from './Table.module.scss';
import useColumns from './useColumns.hook';

interface TableProps {
  dataSource: BusinessTripApprovalItemType[];
}

const Table: FC<TableProps> = (props) => {
  const { dataSource } = props;

  const columns = useColumns();

  return <TableLib className={s.table} rowKey="num" columns={columns} dataSource={dataSource} />;
};

export default Table;
