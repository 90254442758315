import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { getSocialProgramRequestWithIdRoute } from 'routes/social-program/list';

import { GetSocialRequests1cResponseType } from 'services/1c/cApiService.types';

import FiltersV2 from 'containers/filters-v2';

import { SocialProgramRequestType } from 'types/socialProgram.types';

import { FormResponseType } from '../../socialProgramRequests.utils';
import s from './RequestsTable.module.scss';
import useColumns from './useColumns.hook';

interface RequestsTableProps {
  data?: GetSocialRequests1cResponseType;
  isFetching: boolean;
}

const RequestsTable: FC<RequestsTableProps> = (props) => {
  const { data, isFetching } = props;

  const navigate = useNavigate();

  const columns = useColumns();

  const onRow = (request: SocialProgramRequestType) => ({
    onClick: () => navigate(getSocialProgramRequestWithIdRoute(request.id)),
  });

  return (
    <FiltersV2.Table<SocialProgramRequestType, FormResponseType>
      className={s.wrapper}
      dataSource={data?.items}
      columns={columns}
      rowKey="id"
      onRow={onRow}
      total={data?.totalCount}
      loading={isFetching}
    />
  );
};

export default RequestsTable;
