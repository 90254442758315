import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetRenewTermsQuery } from 'services/renew-term/renewApiService';
import { GetRenewTermsPropsType } from 'services/renew-term/renewApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './topManagementPositionDirectory.utils';

interface UseRequestProps {
  form: FormInstance<FormResponseType>;
}

const useRequest = (props: UseRequestProps) => {
  const { form } = props;

  const [getEmployerPositions, { data: employerPositions, isLoading, isFetching }] =
    useLazyGetRenewTermsQuery();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody) {
      const initialRequestBody: GetRenewTermsPropsType = {};

      if (debouncedRequestBody.search) {
        initialRequestBody.employerName = debouncedRequestBody.search;
      }

      getEmployerPositions(initialRequestBody);
    }
  }, [debouncedRequestBody, getEmployerPositions]);

  return {
    employerPositions,
    isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useRequest;
