import { FC, memo, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getNotificationTemplateWithIdRoute } from 'routes/notification/list';
import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetNotificationTemplatesQuery } from 'services/notification-template/notificationTemplateApiService';
import { GetNotificationTemplatesPropsType } from 'services/notification-template/notificationTemplateApiService.types';

import { Table } from 'gazprom-ui-lib';

import { NotificationTemplateType } from 'types/notification.types';

import s from './NotificationTemplateTable.module.scss';
import { INITIAL_REQUEST_BODY, RequestBody } from './notificationTemplateTable.utils';
import useColumns from './useColumns.hook';

interface NotificationTemplateTableProps {
  search?: string;
}

const NotificationTemplateTable: FC<NotificationTemplateTableProps> = (props) => {
  const { search } = props;

  const navigate = useNavigate();

  const [requestBody, setRequestBody] = useState<RequestBody>(INITIAL_REQUEST_BODY);

  const [
    getNotificationTemplates,
    { data: notificationTemplatesData, isFetching: notificationDataIsFetching },
  ] = useLazyGetNotificationTemplatesQuery();

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    if (typeof search === 'string') {
      setRequestBody((prevState) => ({
        ...prevState,
        page: 1,
        search,
      }));
    }
  }, [search]);

  useEffect(() => {
    const search = debouncedRequestBody.search || undefined;
    const defaultBody: GetNotificationTemplatesPropsType = {
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
      search,
    };

    if (debouncedRequestBody.property && debouncedRequestBody.order) {
      defaultBody.sort = [
        { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
      ];
    }

    getNotificationTemplates(defaultBody);
  }, [debouncedRequestBody, getNotificationTemplates]);

  const columns = useColumns();

  const onRow = ({ id }: NotificationTemplateType) => ({
    onClick: () => {
      navigate(getNotificationTemplateWithIdRoute(id));
    },
  });

  return (
    <Table
      className={s.table}
      rowKey="id"
      dataSource={notificationTemplatesData?.items}
      columns={columns}
      showSorterTooltip={false}
      onChange={handleTableChange(setRequestBody)}
      onRow={onRow}
      pagination={{
        defaultCurrent: requestBody.page,
        defaultPageSize: requestBody.size,
        total: notificationTemplatesData?.totalCount,
      }}
      loading={notificationDataIsFetching}
    />
  );
};

export default memo(NotificationTemplateTable);
