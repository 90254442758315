import { FC } from 'react';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_FORM_STATE, FormResponseType } from './topManagementPositionDirectory.utils';
import useRequest from './useRequest.hook';

const TopManagementPositionDirectory: FC = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { employerPositions, isLoading, isFetching } = useRequest({ form });

  const loading = isLoading || isFetching;

  return (
    <WithLoader isLoading={isLoading}>
      <WithOverflow>
        <Header />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <Filters />
          <Table dataSource={employerPositions} loading={loading} />
        </FiltersV2>
      </WithOverflow>
    </WithLoader>
  );
};

export default TopManagementPositionDirectory;
