import {
  ACCESS_CONTROL_ROUTE,
  ACCESS_CONTROL_USERS_ROUTE,
  ACCESS_CONTROL_USER_ROLES_ROUTE,
} from 'routes/access-control/list';
import {
  DOCUMENTS_BUSINESS_TRIPS_ROUTE,
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE,
  DOCUMENTS_RECRUIT_ROUTE,
  DOCUMENTS_ROUTE,
  DOCUMENTS_SINGS_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
} from 'routes/documents/list';
import { EMPLOYEE_GROUPS_ROUTE } from 'routes/employee-groups/list';
import { EVENTS_ROUTE } from 'routes/events/list';
import { FAQ_ROUTE } from 'routes/faq/list';
import {
  GUIDE_CATEGORIES_ROUTE,
  GUIDE_CONTACTS_ROUTE,
  GUIDE_HANDBOOK_ROUTE,
  GUIDE_ROUTE,
} from 'routes/guide/list';
import { NEWS_ROUTE } from 'routes/news/list';
import {
  NOTIFICATION_MAILING_MANAGEMENT_ROUTE,
  NOTIFICATION_ROUTE,
  NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
} from 'routes/notification/list';
import {
  SETTINGS_1C_INSTANCES_ROUTE,
  SETTINGS_DECLARATIONS_ROUTE,
  SETTINGS_LNA_EMPLOYMENT_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_TELEGRAM_CHANNELS_ROUTE,
} from 'routes/settings/list';
import {
  SOCIAL_PROGRAM_PROGRAMS_ROUTE,
  SOCIAL_PROGRAM_REQUESTS_ROUTE,
  SOCIAL_PROGRAM_ROUTE,
} from 'routes/social-program/list';
import { SURVEYS_EVENT_ROUTE, SURVEYS_ONCE_ROUTE, SURVEYS_ROUTE } from 'routes/surveys/list';
import { THANK_YOU_ROUTE } from 'routes/thank-you/list';
import {
  TOP_MANAGEMENT_FORMS_ROUTE,
  TOP_MANAGEMENT_POSITIONS_DIRECTORY_ROUTE,
  TOP_MANAGEMENT_ROUTE,
} from 'routes/top-management/list';
import { t } from 'tools/i18n';

import { SidebarRouteType } from 'gazprom-ui-lib';

export const sidebarButtons: SidebarRouteType[] = [
  {
    path: THANK_YOU_ROUTE,
    label: t('common_thanks'),
    icon: 'thanking',
  },
  {
    path: NEWS_ROUTE,
    label: t('common_news'),
    icon: 'news',
  },
  {
    path: EVENTS_ROUTE,
    label: t('common_events'),
    icon: 'eventFilled',
  },
  {
    path: FAQ_ROUTE,
    label: t('common_faq'),
    icon: 'faq',
  },
  {
    path: SOCIAL_PROGRAM_ROUTE,
    label: t('common_social_programs'),
    icon: 'social',
    items: [
      { label: t('common_programs_list'), path: SOCIAL_PROGRAM_PROGRAMS_ROUTE },
      { label: t('common_requests'), path: SOCIAL_PROGRAM_REQUESTS_ROUTE },
    ],
  },
  {
    path: NOTIFICATION_ROUTE,
    label: t('common_notifications'),
    icon: 'notification',
    items: [
      { label: t('notification_mailing_management'), path: NOTIFICATION_MAILING_MANAGEMENT_ROUTE },
      {
        label: t('notification_system_notifications'),
        path: NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
      },
    ],
  },
  {
    path: SURVEYS_ROUTE,
    label: t('common_surveys'),
    icon: 'questionnairesFilled',
    items: [
      { label: t('surveys_once'), path: SURVEYS_ONCE_ROUTE },
      { label: t('surveys_regular'), path: SURVEYS_EVENT_ROUTE },
    ],
  },
  {
    path: EMPLOYEE_GROUPS_ROUTE,
    label: t('common_employee_groups'),
    icon: 'groups',
  },
  {
    path: GUIDE_ROUTE,
    label: t('common_watch_guide'),
    icon: 'home',
    items: [
      { label: t('guide_handbook'), path: GUIDE_HANDBOOK_ROUTE },
      { label: t('guide_important_numbers'), path: GUIDE_CONTACTS_ROUTE },
      { label: t('guide_categories'), path: GUIDE_CATEGORIES_ROUTE },
    ],
  },
  {
    path: DOCUMENTS_ROUTE,
    label: t('common_documents'),
    icon: 'documents',
    items: [
      { label: t('common_inquiries'), path: DOCUMENTS_INQUIRIES_ROUTE },
      { label: t('common_statements'), path: DOCUMENTS_STATEMENTS_ROUTE },
      { label: t('common_documents_for_sign'), path: DOCUMENTS_SINGS_ROUTE },
      { label: t('kedo_review_tasks'), path: DOCUMENTS_KEDO_REVIEW_TASKS_ROUTE },
      { label: t('documents_business_trips'), path: DOCUMENTS_BUSINESS_TRIPS_ROUTE },
      { label: t('common_recruit'), path: DOCUMENTS_RECRUIT_ROUTE },
    ],
  },
  {
    path: ACCESS_CONTROL_ROUTE,
    label: t('common_access_control'),
    icon: 'key',
    items: [
      { label: t('common_users'), path: ACCESS_CONTROL_USERS_ROUTE },
      { label: t('common_user_roles'), path: ACCESS_CONTROL_USER_ROLES_ROUTE },
    ],
  },
  {
    path: SETTINGS_ROUTE,
    label: t('common_settings'),
    icon: 'settings',
    items: [
      { label: t('common_declaration_types'), path: SETTINGS_DECLARATIONS_ROUTE },
      { label: t('1c_import'), path: SETTINGS_1C_INSTANCES_ROUTE },
      { label: t('telegram_synchronization'), path: SETTINGS_TELEGRAM_CHANNELS_ROUTE },
      { label: t('1c_lna_employment'), path: SETTINGS_LNA_EMPLOYMENT_ROUTE },
    ],
  },
  {
    path: TOP_MANAGEMENT_ROUTE,
    label: t('top_management_title'),
    icon: 'chief',
    items: [
      {
        label: t('top_management_forms'),
        path: TOP_MANAGEMENT_FORMS_ROUTE,
      },
      {
        label: t('top_management_position_dictionary'),
        path: TOP_MANAGEMENT_POSITIONS_DIRECTORY_ROUTE,
      },
    ],
  },
];
