import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_SINGS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { TaskAssignmentType } from 'types/taskAssignment.types';

import s from './DocumentsSigns.module.scss';
import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, FormResponseType } from './documentsSigns.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsSigns = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();

  const columns = useColumns();

  const { data, isFetching, isLoading } = useGetData({ form });

  const handleOnRow = (record: TaskAssignmentType) => ({
    onClick: () => navigate(`${DOCUMENTS_SINGS_ROUTE}/${record.id}/${record.executor.id}`),
  });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('common_documents_for_sign')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE} className={s.wrapper}>
        <Filters hideSearch />
        <WithLoader isLoading={isLoading}>
          <FiltersV2.Table<TaskAssignmentType, FormResponseType>
            scroll={{ y: 800, x: 700 }}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            onRow={handleOnRow}
            total={data?.totalCount}
            loading={isFetching}
          />
        </WithLoader>
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsSigns;
