import { useEffect, useState } from 'react';

import { useGetEmployersQuery } from 'services/social-program/socialProgramApiService';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { MappedSocialPrograms } from 'types/socialProgram.types';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import {
  DEFAULT_FORM_STATE,
  FormResponseType,
  PAGE_SETTING_ALL_ITEMS,
  mapSocialPrograms,
} from './socialProgram.utils';
import useRequest from './useRequest.hook';

const SocialProgram = () => {
  const [form] = Form.useForm<FormResponseType>();

  const [mappedSocialPrograms, setMappedSocialPrograms] = useState<MappedSocialPrograms>([]);

  const { data: employers, isLoading: isEmployersLoading } =
    useGetEmployersQuery(PAGE_SETTING_ALL_ITEMS);

  const {
    data: socialPrograms,
    isFetching,
    isLoading: isSocialProgramsLoading,
  } = useRequest({ form });

  const isLoading = isSocialProgramsLoading || isEmployersLoading;

  useEffect(() => {
    if (socialPrograms && employers) {
      setMappedSocialPrograms(mapSocialPrograms(socialPrograms.items, employers.content));
    }
  }, [employers, socialPrograms]);

  return (
    <WithLoader isLoading={isLoading}>
      <WithOverflow>
        <Header total={socialPrograms?.totalCount} />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <Filters />
          <Table
            totalCount={socialPrograms?.totalCount}
            loading={isFetching}
            dataSource={mappedSocialPrograms}
          />
        </FiltersV2>
      </WithOverflow>
    </WithLoader>
  );
};

export default SocialProgram;
