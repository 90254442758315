import { useMemo } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Button, TableProps, Typography } from 'gazprom-ui-lib';

import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { FileType } from 'types/file.types';
import { KedoReviewTaskType } from 'types/review-task.types';

import s from './ActiveLnaTable.module.scss';

interface UseColumnsHookProps {
  handleExclude: (id: string) => void;
}

const useColumnsHook = (props: UseColumnsHookProps): TableProps<KedoReviewTaskType>['columns'] => {
  const { handleExclude } = props;
  return useMemo(
    () => [
      {
        title: t('common_document_name'),
        dataIndex: 'docName',
        render: (docName: string) => (
          <Typography.Text weight="500" size="14">
            {docName}
          </Typography.Text>
        ),
      },
      {
        title: t('common_file'),
        dataIndex: 'files',
        render: (files: FileType[]) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {files.map((file) => file.fileName).join(', ')}
          </Typography.Text>
        ),
      },
      {
        title: t('common_status'),
        dataIndex: 'status',
        render: (status: { state: string; name: string }) => {
          return (
            <TableStatus status={status.state.toUpperCase() as STATUSES}>{status.name}</TableStatus>
          );
        },
      },
      {
        title: t('common_active_date'),
        dataIndex: 'sentDate',
        width: 150,
        render: (sentDate: string) =>
          sentDate && (
            <Typography.Text weight="500" size="14">
              {dayjs(sentDate).format('DD.MM.YYYY')}
            </Typography.Text>
          ),
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, row: KedoReviewTaskType) => (
          <Button
            leftIcon="unpublish"
            type="link"
            className={s.action}
            onClick={(e) => {
              e.stopPropagation();
              handleExclude(row.id);
            }}>
            {t('common_exclude')}
          </Button>
        ),
      },
    ],
    [handleExclude],
  );
};

export default useColumnsHook;
