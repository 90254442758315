import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useGetEmployerQuery,
  useGetSocialProgramDocumentsQuery,
  useGetSocialProgramEmployeeRequirementsQuery,
  useGetSocialProgramPayoutAmountQuery,
  useGetSocialTypesQuery,
} from 'services/social-program-category/socialProgramCategoryApiService';
import {
  useCreateSocialProgramMutation,
  useGetSocialProgramByIdQuery,
  useUpdateSocialProgramMutation,
} from 'services/social-program/socialProgramApiService';
import { CreateSocialProgramsPropsType } from 'services/social-program/socialProgramApiService.types';

import { Form } from 'gazprom-ui-lib';

import CommonCheck from 'components/form/common-check';
import CommonSelect from 'components/form/common-select';
import MultiTagsSelect from 'components/form/multi-tags-select';
import WithLoader from 'containers/wrappers/with-loader';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import { ModalAddDocuments, ModalAddPayment, ModalAddRequirement } from '../social-program-modals';
import { PAGE_SETTING_ALL_ITEMS } from '../socialProgram.utils';
import s from './SocialProgramFrom.module.scss';
import Actions from './containers/actions';
import CategorySelect from './containers/category-select';
import CommonInput from './containers/common-input';
import CommonTextArea from './containers/common-text-area';
import { SOCIAL_PROGRAM_FORM_NAME, mapSocialProgramById } from './socialProgramForm.utils';

const SocialProgramForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<ReturnType<
    typeof mapSocialProgramById
  > | null>(null);

  const { data: employeeRequirementsOptions, isLoading: isEmployeeRequirementsOptionsLoading } =
    useGetSocialProgramEmployeeRequirementsQuery(PAGE_SETTING_ALL_ITEMS);
  const { data: employersOptions, isLoading: isEmployersOptionsLoading } =
    useGetEmployerQuery(PAGE_SETTING_ALL_ITEMS);
  const { data: documentsOptions, isLoading: isDocumentsOptionsLoading } =
    useGetSocialProgramDocumentsQuery(PAGE_SETTING_ALL_ITEMS);
  const { data: payoutAmountOptions, isLoading: isPayoutAmountOptionsLoading } =
    useGetSocialProgramPayoutAmountQuery(PAGE_SETTING_ALL_ITEMS);
  const [createSocialProgram, { isLoading: createSocialProgramIsLoading }] =
    useCreateSocialProgramMutation();
  const [updateSocialProgram, { isLoading: updateSocialProgramIsLoading }] =
    useUpdateSocialProgramMutation();
  const { data: socialProgramById, isLoading: isSocialProgramByIdLoading } =
    useGetSocialProgramByIdQuery(id ?? skipToken);
  const { data: socialTypesOptions, isLoading: isSocialTypesOptionsLoading } =
    useGetSocialTypesQuery({ type: KEDO_TYPE_ENUM.SOCIAL, ...PAGE_SETTING_ALL_ITEMS });

  const isLoading =
    isEmployeeRequirementsOptionsLoading ||
    isEmployersOptionsLoading ||
    isDocumentsOptionsLoading ||
    isPayoutAmountOptionsLoading ||
    isSocialProgramByIdLoading ||
    isSocialTypesOptionsLoading;

  useEffect(() => {
    if (socialProgramById) {
      setInitialValues(mapSocialProgramById(socialProgramById));
    }
  }, [form, socialProgramById]);

  const onClose = () => {
    navigate(-1);
  };

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose,
      onConfirm: onClose,
    },
  });

  const handleCreate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose,
      onConfirm: onClose,
      title: {
        children: t('common_added_successfully'),
      },
    },
  });

  const onFinish = (values: CreateSocialProgramsPropsType) => {
    if (socialProgramById) {
      updateSocialProgram({ id: id!, body: values }).then(handleUpdate);
    } else {
      createSocialProgram(values).then(handleCreate);
    }
  };

  const mutationIsLoading = updateSocialProgramIsLoading || createSocialProgramIsLoading;

  return (
    <WithLoader isLoading={isLoading}>
      <Form form={form} className={s.form} onFinish={onFinish} id={SOCIAL_PROGRAM_FORM_NAME}>
        <CommonTextArea
          name="nameProgram"
          placeholder={t('common_enter_text')}
          title={t('social_program_new_program_name')}
          totalLength="260"
          initialValue={initialValues?.nameProgram}
        />
        <CategorySelect
          name="categoryId"
          title={t('social_program_new_choose_category')}
          placeholder={t('common_choose_category_from_list_or_add')}
          buttonText={t('common_add_new_category')}
          initialValue={initialValues?.categoryId}
        />
        <MultiTagsSelect
          name="socialEmployeeRequirementIds"
          title={t('social_program_new_employee_requirements')}
          placeholder={t('social_program_new_choose_requirements')}
          buttonText={t('social_program_modal_add_new_req')}
          options={employeeRequirementsOptions!}
          initialValues={initialValues?.socialEmployeeRequirementIds}
          ModalComponent={ModalAddRequirement}
        />
        <CommonSelect
          name="organizationId"
          title={t('social_program_new_organization')}
          placeholder={t('common_choose_organization_from_list')}
          options={employersOptions!}
          initialValue={initialValues?.organizationId}
          isRequired={false}
        />
        <MultiTagsSelect
          name="socialProgramDocumentIds"
          title={t('social_program_new_required_documents')}
          placeholder={t('social_program_new_choose_document')}
          buttonText={t('social_program_new_add_document')}
          options={documentsOptions!}
          initialValues={initialValues?.socialProgramDocumentIds}
          ModalComponent={ModalAddDocuments}
        />
        <MultiTagsSelect
          name="payoutAmountIds"
          title={t('social_program_new_payout_amount')}
          placeholder={t('social_program_new_select_payout_amount')}
          buttonText={t('social_program_new_add_payout_amount')}
          options={payoutAmountOptions!}
          initialValues={initialValues?.payoutAmountIds}
          ModalComponent={ModalAddPayment}
        />
        <CommonInput
          name="paymentTerm"
          title={t('social_program_new_payment_term')}
          placeholder={t('social_program_new_select_payment_term')}
          maxWidth={255}
          initialValue={initialValues?.paymentTerm}
        />
        <CommonInput
          name="deadlineSendDocs"
          title={t('social_program_new_documents_deadline')}
          placeholder={t('social_program_new_select_documents_deadline')}
          maxWidth={255}
          initialValue={initialValues?.deadlineSendDocs}
        />
        <CommonSelect
          name="kedoStatementId"
          title={t('social_program_type')}
          placeholder={t('social_program_type_placeholder')}
          options={socialTypesOptions!}
          initialValue={initialValues?.kedoStatementId}
          isRequired={false}
        />
        <CommonCheck
          name="isPublish"
          title={t('social_program_new_status')}
          checkboxText={t('social_program_new_published')}
          initialValue={initialValues?.isPublish}
        />
        <Actions saveButtonIsLoading={mutationIsLoading} />
      </Form>
    </WithLoader>
  );
};

export default SocialProgramForm;
