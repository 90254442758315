import { FC } from 'react';

import { useParams } from 'react-router-dom';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import {
  DEFAULT_FORM_STATE,
  FormResponseType,
} from './topManagementPositionDirectoryDetails.utils';
import useRequest from './useRequest.hook';

const TopManagementPositionDirectoryDetails: FC = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { employerId } = useParams<{ employerId: string }>();

  const { employerPositionsDetails, isLoading, isFetching } = useRequest({ employerId, form });

  const { employerName, totalCount } = employerPositionsDetails ?? {};

  return (
    <WithLoader isLoading={isLoading}>
      <WithOverflow>
        <Header employerName={employerName} totalCount={totalCount} />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <Filters employerId={employerId} />
          <Table dataSource={employerPositionsDetails} loading={isFetching} />
        </FiltersV2>
      </WithOverflow>
    </WithLoader>
  );
};

export default TopManagementPositionDirectoryDetails;
