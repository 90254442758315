import { useEffect, useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSurveysQuery } from 'services/surveys/surveysApiService';
import {
  CONDITION_SURVEY_ENUM,
  GetSurveysPropsType,
} from 'services/surveys/surveysApiService.types';

import { InputProps } from 'gazprom-ui-lib';

import { DEFAULT_REQUEST_BODY, RequestBodyType } from '../surveys.utils';
import useMatchOnceRoute from './useMatchOnceRoute';

const useSurveys = () => {
  const isOnceSurveysRoute = useMatchOnceRoute();
  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);

  const [
    getSurveys,
    { data: surveysData, isLoading: surveysIsLoading, isFetching: surveysIsFetching },
  ] = useLazyGetSurveysQuery();

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: DEFAULT_REQUEST_BODY.page,
    }));
  };

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    const initialRequestBody: GetSurveysPropsType = {
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
      condition: isOnceSurveysRoute ? CONDITION_SURVEY_ENUM.ONCE : CONDITION_SURVEY_ENUM.EVENT,
    };

    if (debouncedRequestBody.search) {
      initialRequestBody.name = debouncedRequestBody.search;
    }

    if (debouncedRequestBody.property && debouncedRequestBody.order) {
      initialRequestBody.sort = [
        {
          order: debouncedRequestBody.order,
          property: `ANKETA.${debouncedRequestBody.property}`,
        },
      ];
    }

    getSurveys(initialRequestBody);
  }, [debouncedRequestBody, getSurveys, isOnceSurveysRoute]);

  return {
    surveysData,
    surveysIsLoading,
    surveysIsFetching,
    requestBody,
    setRequestBody,
    handleSearchChange,
  };
};

export default useSurveys;
