import { useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  useAssignSurveyMutation,
  useCancelSurveyMutation,
  useGetEmployerQuery,
  useGetSurveysEmployeeGroupsQuery,
  useGetSurveysListQuery,
  useUpdateSurveyMutation,
} from 'services/surveys/surveysApiService';

import { FormInstance, handleRequest } from 'gazprom-ui-lib';

import { DEFAULT_PAGE_DATA, getReceivers, validateFields } from 'pages/surveys/surveys.utils';

import { NewSurveyFinishFormType, NewSurveyType } from 'types/surveys.types';

import { SURVEYS_ONCE_MODAL_ENUM } from './surveyFormOnce.utils';

interface Props {
  form: FormInstance;
  refetchHandler?: () => void;
}

const useFormAssignHandler = (props: Props) => {
  const { form, refetchHandler } = props;

  const { id } = useParams<{ id: string }>();

  const [modalOpen, setModalOpen] = useState<SURVEYS_ONCE_MODAL_ENUM>(
    SURVEYS_ONCE_MODAL_ENUM.EMPTY,
  );

  const { data: employeeGroupsResult } = useGetSurveysEmployeeGroupsQuery(DEFAULT_PAGE_DATA);
  const { data: organizationOptions } = useGetEmployerQuery(DEFAULT_PAGE_DATA);
  const { data: surveysList } = useGetSurveysListQuery();

  const [updateSurvey, { isLoading: isUpdateSurveyLoading }] = useUpdateSurveyMutation();
  const [assignSurvey, { isLoading: isAssignSurveyLoading }] = useAssignSurveyMutation();
  const [cancelSurvey, { isLoading: isCancelSurveyLoading }] = useCancelSurveyMutation();

  const isLoading = isUpdateSurveyLoading || isAssignSurveyLoading || isCancelSurveyLoading;

  const cancelModalHandler = () => setModalOpen(SURVEYS_ONCE_MODAL_ENUM.EMPTY);

  const assignHandler = (formData: NewSurveyFinishFormType) => {
    if (!id || !employeeGroupsResult || !organizationOptions) {
      return;
    }

    const { name, receivers, organization, ...rest } = formData;
    const result = {
      ...surveysList?.surveysInfo[name],
      ...rest,
      receivers: getReceivers(receivers, employeeGroupsResult, organization, organizationOptions),
    } as NewSurveyType;

    const handleAssign = () => {
      assignSurvey(id);
      refetchHandler?.();
    };

    updateSurvey({ id, body: result }).then(handleRequest({ onSuccess: handleAssign }));
  };

  const appointButtonHandler = async () => {
    const isFieldsValid = await validateFields(form);

    if (!isFieldsValid) {
      return;
    }

    setModalOpen(SURVEYS_ONCE_MODAL_ENUM.ASSIGN);
  };

  const acceptAppointButtonHandler = () => {
    const formData = form.getFieldsValue();

    cancelModalHandler();
    assignHandler(formData);
  };

  const recallButtonHandler = () => {
    setModalOpen(SURVEYS_ONCE_MODAL_ENUM.RECALL);
  };

  const acceptRecallButtonHandler = () => {
    if (id) {
      cancelSurvey(id);
      cancelModalHandler();
    }
  };

  return {
    isLoading,
    modalOpen,
    cancelModalHandler,
    appointButtonHandler,
    acceptAppointButtonHandler,
    recallButtonHandler,
    acceptRecallButtonHandler,
  };
};

export default useFormAssignHandler;
