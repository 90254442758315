import { FC } from 'react';

import dayjs from 'dayjs';
import { TOP_MANAGEMENT_FORMS_ROUTE } from 'routes/top-management/list';
import { t } from 'tools/i18n';

import { Flex, Icon, Tag, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { RenewFormItemStatusType } from 'types/topManagement.types';

import { getFormDetailsStatusConfig } from '../../topManagementFormsDetails.utils';
import s from './Header.module.scss';

interface HeaderProps {
  createdAt?: string;
  updatedAt?: string;
  status?: RenewFormItemStatusType;
}

const Header: FC<HeaderProps> = (props) => {
  const { createdAt, updatedAt, status } = props;

  const description = (
    <Flex align="center" gap="16">
      <Flex align="center" gap="8">
        {`${t('employee_groups_column_created_date')}: `}
        <Flex gap="4" align="center">
          <Icon name="calendar" />
          <Typography.Text type="secondary">
            {dayjs.utc(createdAt).local().format('DD MMMM YYYY, HH:mm')}
          </Typography.Text>
        </Flex>
      </Flex>
      <Flex align="center" gap="8">
        {`${t('common_last_change')}: `}
        <Flex gap="4" align="center">
          <Icon name="calendar" />
          <Typography.Text type="secondary">
            {dayjs.utc(updatedAt).local().format('DD MMMM YYYY, HH:mm')}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );

  const statusTagConfig = getFormDetailsStatusConfig(status);

  return (
    <ContentHeader>
      <ContentHeader.Title goBackLink={TOP_MANAGEMENT_FORMS_ROUTE} description={description}>
        {t('common_view_statement')}
      </ContentHeader.Title>
      {!!statusTagConfig && (
        <ContentHeader.Actions>
          <Tag type={statusTagConfig?.type} className={s.tag}>
            <Icon name={statusTagConfig?.icon} />
            <span className={s.title}>{statusTagConfig?.title}</span>
          </Tag>
        </ContentHeader.Actions>
      )}
    </ContentHeader>
  );
};

export default Header;
