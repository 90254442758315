import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';

import SearchWithFilters from 'containers/search-with-filters';
import WithLoader from 'containers/wrappers/with-loader';

import Header from '../containers/header';
import Table from '../containers/table';
import useSurveys from '../containers/useSurveys.hook';
import useColumns from './useColumns.hook';

const SurveysOnce = () => {
  const columns = useColumns();
  const {
    surveysData,
    surveysIsFetching,
    surveysIsLoading,
    requestBody,
    handleSearchChange,
    setRequestBody,
  } = useSurveys();

  const { items = [], totalCount } = surveysData || {};
  const { page, size, search } = requestBody;

  return (
    <WithLoader isLoading={surveysIsLoading}>
      <Header total={totalCount} title={t('surveys_once')} />
      <SearchWithFilters
        handleSearchChange={handleSearchChange}
        search={search}
        showSelects={false}
        searchPlaceholder={t('common_search')}
      />
      <Table
        dataSource={items}
        columns={columns}
        isLoading={surveysIsFetching}
        paginationConfig={{
          defaultCurrent: page,
          defaultPageSize: size,
          current: page,
          total: totalCount,
        }}
        onChange={handleTableChange(setRequestBody)}
      />
    </WithLoader>
  );
};

export default SurveysOnce;
