import { useCallback, useMemo } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useGetFaqCategoriesQuery, useGetFaqsQuery } from 'services/faq/faqApiService';

import { FaqType } from 'types/faq.types';

import { FaqTreeDataType, RequestBodyType, UnionType } from './faq.utils';

interface UseDataHookProps {
  requestBody: RequestBodyType;
}

const useDataHook = (props: UseDataHookProps) => {
  const { requestBody } = props;

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const {
    data: faqs,
    isFetching: faqsIsFetching,
    isLoading: faqsIsLoading,
  } = useGetFaqsQuery({ page: 1, size: 500 });
  const {
    data: faqCategories,
    isFetching: faqCategoriesIsFetching,
    isLoading: faqCategoriesIsLoading,
  } = useGetFaqCategoriesQuery({
    page: 1,
    size: 500,
  });

  const filterByStatus = useCallback(
    (data: UnionType) => {
      if (debouncedRequestBody.filters?.status?.length === 1) {
        return data.isPublish === debouncedRequestBody.filters.status[0];
      }

      return true;
    },
    [debouncedRequestBody.filters?.status],
  );

  const filterFaqs = useCallback(
    (data: FaqType) => {
      let flag = true;

      if (debouncedRequestBody.filters) {
        flag = filterByStatus(data);
      }

      if (flag) {
        if (debouncedRequestBody.search) {
          const preparedSearch = debouncedRequestBody.search.trim().toLowerCase();

          flag = data.answer.toLowerCase().trim().includes(preparedSearch);
        }
      }

      return flag;
    },
    [filterByStatus, debouncedRequestBody],
  );

  const combinedTreeData = useMemo(() => {
    if (faqs && faqCategories) {
      const combinedFaqsAndCategories = [
        ...faqCategories.categories.filter(filterByStatus),
        ...faqs.faqLists.filter(filterFaqs),
      ];

      return createDataTree(combinedFaqsAndCategories);
    }

    return [];
  }, [faqs, faqCategories, filterByStatus, filterFaqs]);

  return {
    combinedTreeData,
    isFetching: faqCategoriesIsFetching || faqsIsFetching,
    isLoading: faqCategoriesIsLoading || faqsIsLoading,
  };
};

const createDataTree = (dataset: UnionType[]): FaqTreeDataType[] => {
  const hashTable = Object.create(null);
  const dataTree: FaqTreeDataType[] = [];

  const modifyUnionData = (unionData: UnionType) => {
    const isFaq = 'answer' in unionData;

    hashTable[unionData.id] = {
      key: unionData.id,
      isPublish: unionData.isPublish,
      updatedAt: unionData.updatedAt,
      type: isFaq ? 'faq' : 'category',
      title: isFaq ? unionData.title : unionData.nameCategory,
      parentId: isFaq ? unionData.category.id : unionData.parentCategory.id,
      organization: isFaq ? unionData.organization : undefined,
    };
  };

  const setTreeData = (unionData: UnionType) => {
    const isFaq = 'answer' in unionData;

    const parentId = isFaq ? unionData.category.id : unionData.parentCategory.id;

    if (parentId) {
      if (hashTable[parentId]) {
        if (hashTable[parentId].children) {
          hashTable[parentId].children.push(hashTable[unionData.id]);
        } else {
          hashTable[parentId].children = [hashTable[unionData.id]];
        }
      }
    } else {
      dataTree.push(hashTable[unionData.id]);
    }
  };

  dataset.forEach(modifyUnionData);
  dataset.forEach(setTreeData);

  return dataTree;
};

export default useDataHook;
