import { SORT_ENUM } from 'types/sort.types';

export type RequestBody = {
  page: number;
  size: number;
  property?: string;
  order: SORT_ENUM;
  search: string;
};

export const INITIAL_REQUEST_BODY = {
  search: '',
  page: 1,
  order: SORT_ENUM.DESC,
  property: 'createdAt',
};
