import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getEmployeeGroupWithIdRoute } from 'routes/employee-groups/list';
import { t } from 'tools/i18n';

import { useRemoveGroupsMutation } from 'services/employee-groups-new/employeeGroupsNewApiService';

import { ButtonProps, Form, handleRequest } from 'gazprom-ui-lib';

import TableSelectedActions from 'components/table/table-selected-actions';
import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { EmployeeGroupType } from 'types/employeeGroups.types';

import s from './EmployeeGroups.module.scss';
import Header from './containers/header';
import RemoveModal from './containers/remove-modal';
import { DEFAULT_FORM_STATE, EMPLOYEE_MODALS, FormResponseType } from './employeeGroups.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const EmployeeGroups = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalActive, setIsModalActive] = useState<EMPLOYEE_MODALS>(EMPLOYEE_MODALS.EMPTY);

  const { employeeGroups, isFetching, isLoading } = useGetData({ form });
  const [removeGroups, { isLoading: removeGroupsIsLoading }] = useRemoveGroupsMutation();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) =>
    setSelectedRowKeys(newSelectedRowKeys);
  const removeSelection = () => setSelectedRowKeys([]);

  const handleModalClose = () => setIsModalActive(EMPLOYEE_MODALS.EMPTY);

  const handleRemoveGroup = () => {
    removeGroups(selectedRowKeys)
      .then(handleRequest({ onSuccess: removeSelection }))
      .finally(handleModalClose);
  };

  const buttons: ButtonProps[] = [
    {
      children: t('news_deselect'),
      leftIcon: 'delete',
      onClick: removeSelection,
    },
    {
      children: t('common_remove'),
      danger: true,
      leftIcon: 'delete',
      onClick: () => setIsModalActive(EMPLOYEE_MODALS.REMOVE),
    },
  ];

  const handleOnRow = ({ id }: { id: string }) => ({
    onClick: () => {
      navigate(getEmployeeGroupWithIdRoute(id));
    },
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = useColumns();
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <WithOverflow>
        <Header />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE} className={s.wrapper}>
          <FiltersV2.TableFilters>
            <FiltersV2.Search />
          </FiltersV2.TableFilters>
          {hasSelected && <TableSelectedActions buttons={buttons} className={s.actionsContainer} />}
          <WithLoader isLoading={isLoading}>
            <FiltersV2.Table<EmployeeGroupType, FormResponseType>
              className={s.table}
              scroll={{ y: 800, x: 700 }}
              dataSource={employeeGroups?.content}
              columns={columns}
              rowKey="id"
              onRow={handleOnRow}
              total={employeeGroups?.totalCount}
              loading={isFetching}
              rowSelection={rowSelection}
            />
          </WithLoader>
        </FiltersV2>
      </WithOverflow>
      <RemoveModal
        open={isModalActive === EMPLOYEE_MODALS.REMOVE}
        onCancel={handleModalClose}
        handleRemoveGroup={handleRemoveGroup}
        isLoading={removeGroupsIsLoading}
      />
    </>
  );
};

export default EmployeeGroups;
