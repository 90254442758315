import { FC } from 'react';

import { useParams } from 'react-router-dom';

import { useRemovePositionMutation } from 'services/renew-term/renewApiService';
import { GetRenewTermByIdResponseType } from 'services/renew-term/renewApiService.types';

import FiltersV2 from 'containers/filters-v2';

import s from './Table.module.scss';
import { mapTableData } from './table.utils';
import useColumns from './useColumns.hook';

interface TableProps {
  dataSource?: GetRenewTermByIdResponseType;
  loading?: boolean;
}

const Table: FC<TableProps> = (props) => {
  const { employerId } = useParams<{ employerId: string }>();

  const { dataSource, loading } = props;

  const [removePosition, { isLoading: isDeleting }] = useRemovePositionMutation();

  const handleDelete = (positionName: string) => {
    if (employerId) {
      removePosition([{ employerId, positionName }]);
    }
  };

  const columns = useColumns({ handleDelete });

  const isLoading = loading || isDeleting;
  const data = mapTableData(dataSource?.items);

  return (
    <FiltersV2.Table
      rowKey="positionName"
      className={s.table}
      dataSource={data}
      total={dataSource?.totalCount}
      columns={columns}
      loading={isLoading}
    />
  );
};

export default Table;
