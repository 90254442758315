import { Key } from 'react';

import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';

import { RequestBody, ThankYouFiltersType } from '../thank-you.utils';

export const handleDownload = async (
  filters: ThankYouFiltersType & Pick<RequestBody, 'text'>,
  isSelectedAll: boolean,
  selectedRowKeys: Key[],
) => {
  const { authorEmployers, recipientEmployers, status, text } = filters;
  const feedbackIds = isSelectedAll ? [] : selectedRowKeys;

  const body = {
    feedbackIds,
    feedbackFilterParams: {
      authorEmployers: authorEmployers ?? [],
      recipientEmployers: recipientEmployers ?? [],
      status: status ?? [],
      text: text,
    },
  };

  await downloadHandler({
    url: `${BACKEND_V1_BASE_URL}report/feedback`,
    fileName: t('common_report'),
    method: 'POST',
    body: JSON.stringify(body),
    headersFileNameKey: `filename*=UTF-8''`,
  });
};
