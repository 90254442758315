import { ReactElement } from 'react';

import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_STATEMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetTaskByIdQuery } from 'services/1c/cApiService';

import { Button, Flex, Icon, StepsProps, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import ContentHeader from 'containers/content-header';
import DocumentView from 'containers/document-view';
import { TabsTypes } from 'containers/document-view/containers/tabs/tabs.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './DocumentsView.module.scss';
import DocumentsViewFiles from './documents-view-files';
import DocumentsViewParticipants from './documents-view-participants';

const DocumentsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: taskData, isLoading, isError } = useGetTaskByIdQuery(id!);

  const handleGoBack = () => navigate(DOCUMENTS_STATEMENTS_ROUTE);

  const tabs: { key: TabsTypes; label: string; children: ReactElement }[] = [
    {
      key: 'attached',
      label: t('common_attached_files'),
      children: <DocumentsViewFiles />,
    },
    {
      key: 'additional',
      label: t('common_process_participants'),
      children: <DocumentsViewParticipants />,
    },
  ];

  const description = taskData ? (
    <>
      {`${t('common_last_change')}: `}
      <Flex gap="4" align="center">
        <Icon name="calendar" />
        <Typography.Text type="secondary">
          {dayjs.utc(taskData?.updatedAt).local().format('DD MMMM YYYY, HH:mm')}
        </Typography.Text>
      </Flex>
    </>
  ) : null;

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={DOCUMENTS_STATEMENTS_ROUTE} description={description}>
          {t('common_view_statement')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <KedoStatusTag currentKedoStatus={taskData?.currentKedoStatus} size="30" />
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isLoading}>
        <DocumentView isError={isError}>
          <DocumentView.Content>
            <Flex vertical gap="24">
              <DocumentView.Employee employee={taskData?.employee} />

              <Flex vertical gap="8">
                <Typography.Title level={5}>{t('common_statement')}</Typography.Title>
                <Flex vertical>
                  <Typography.Text size="14" type="secondary">
                    {taskData?.category.name}
                  </Typography.Text>
                  <Typography.Text size="14">{taskData?.statement.name}</Typography.Text>
                </Flex>
              </Flex>
              <DocumentView.Tabs items={tabs} />
            </Flex>
            <DocumentView.History historyItems={taskData?.history as StepsProps['items']} />
          </DocumentView.Content>
          <Button type="secondary" className={s.closeButton} onClick={handleGoBack}>
            {t('common_close')}
          </Button>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsView;
