import { FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

interface EmployeeProps {
  employee?: EmployeeType;
}

const Employee: FC<EmployeeProps> = (props) => {
  const { employee } = props;

  const { person, position, employer } = employee ?? {};

  return (
    <Flex vertical gap="8">
      <Typography.Title level={5}>{t('common_employee')}</Typography.Title>
      <Flex vertical>
        <Typography.Text size="10" type="secondary">
          {employer?.name}
        </Typography.Text>
        <Typography.Text size="14" weight="500">
          {`${person?.lastName} ${person?.firstName} ${person?.patronymic}`}
        </Typography.Text>
        <Typography.Text size="12" weight="500" type="secondary">
          {position?.name}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Employee;
