import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEmployeeGroupsQuery } from 'services/employee-groups-new/employeeGroupsNewApiService';
import {
  GetEmployeeGroupsPropsType,
  GetEmployeeGroupsResponseType,
} from 'services/employee-groups/employeeGroupsApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './employeeGroups.utils';

interface UseGetEmployeeGroupsProps {
  form: FormInstance<FormResponseType>;
}

const useGetEmployeeGroups = (
  props: UseGetEmployeeGroupsProps,
): {
  employeeGroups?: GetEmployeeGroupsResponseType;
  isFetching: boolean;
  isLoading: boolean;
} => {
  const { form } = props;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const [getEmployeeGroups, { data: employeeGroups, isFetching, isLoading }] =
    useLazyGetEmployeeGroupsQuery();

  useEffect(() => {
    if (debouncedRequestBody && debouncedRequestBody.page) {
      const { page, size, search } = debouncedRequestBody;
      const preparedBody: GetEmployeeGroupsPropsType = {
        page,
        size,
      };

      if (search) {
        preparedBody.name = search;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          {
            order: debouncedRequestBody.order,
            property: `employee_group.${debouncedRequestBody.property}`,
          },
        ];
      }

      getEmployeeGroups(preparedBody);
    }
  }, [debouncedRequestBody, getEmployeeGroups]);

  return {
    employeeGroups,
    isFetching: isFetching || isDebouncing,
    isLoading,
  };
};

export default useGetEmployeeGroups;
