import { useCallback, useState } from 'react';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { GuideCategoryType } from 'types/guide.types';

import Header from './containers/header';
import ModalAddEdit from './containers/modal-add-edit';
import ModalDelete from './containers/modal-delete';
import { DEFAULT_FORM_STATE, FormResponseType, ModalTypes } from './guideCategories.utils';
import useColumns from './useColumns.hook';
import useRequest from './useRequest.hook';

const GuideCategories = () => {
  const [form] = Form.useForm<FormResponseType>();

  const [modalOpenType, setModalOpenType] = useState<ModalTypes>('');
  const [selectedCategory, setSelectedCategory] = useState<null | GuideCategoryType>(null);

  const { data, isFetching } = useRequest({ form });
  const columns = useColumns({ setSelectedCategory, setModalOpenType });

  const handleOpenCloseModal = useCallback((type?: ModalTypes) => {
    setModalOpenType(type ?? '');
    setSelectedCategory(null);
  }, []);

  const handleOpenAddModal = () => handleOpenCloseModal('add');

  const isAddEditModalOpened = modalOpenType === 'edit' || modalOpenType === 'add';
  const isDeleteModalOpened = modalOpenType === 'delete';

  return (
    <>
      <WithOverflow>
        <Header handleOpenAddModal={handleOpenAddModal} />
        <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
          <FiltersV2.TableFilters>
            <FiltersV2.Search />
          </FiltersV2.TableFilters>
          <FiltersV2.Table<GuideCategoryType, FormResponseType>
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            showSorterTooltip={false}
            loading={isFetching}
          />
        </FiltersV2>
      </WithOverflow>
      <ModalAddEdit
        open={isAddEditModalOpened}
        handleClose={handleOpenCloseModal}
        selectedCategory={selectedCategory}
      />
      <ModalDelete
        open={isDeleteModalOpened}
        handleClose={handleOpenCloseModal}
        selectedCategory={selectedCategory}
      />
    </>
  );
};

export default GuideCategories;
