import { handleFormTableChange } from 'utils/table.utils';

import { Form, FormInstance, Table as TableLib, TableProps as TablePropsLib } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';

import { SortOrderPropertyType } from 'types/sort.types';

import s from './Table.module.scss';

interface TableProps<T> extends TablePropsLib<T> {
  total?: number;
  hasFiltersApplied?: boolean;
  fallbackSort?: SortOrderPropertyType;
}

const Table = <T extends object, F extends object>(props: TableProps<T>) => {
  const {
    fallbackSort,
    hasFiltersApplied,
    pagination = true,
    total,
    loading,
    ...otherProps
  } = props;

  const form = Form.useFormInstance<FormInstance<F>>();

  const defaultCurrent: number = form.getFieldValue('page');
  const defaultPageSize: number = form.getFieldValue('size');

  let paginationToUse: TablePropsLib<T>['pagination'];

  if (typeof pagination === 'boolean') {
    paginationToUse = {
      current: defaultCurrent,
      defaultCurrent,
      defaultPageSize,
      total,
    };
  } else if (typeof pagination === 'object') {
    paginationToUse = pagination;
  }

  const emptyText = (
    <Empty
      className={s.emptyContent}
      title="common_no_records_found"
      description={hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''}
    />
  );

  return (
    <>
      <TableLib<T>
        pagination={paginationToUse}
        onChange={handleFormTableChange(form, fallbackSort)}
        locale={{ emptyText }}
        loading={loading}
        {...otherProps}
      />
      <Form.Item name="page" noStyle />
      <Form.Item name="size" noStyle />
      <Form.Item name="order" noStyle />
      <Form.Item name="property" noStyle />
    </>
  );
};

export default Table;
