import { useEffect } from 'react';

import { useLazyGetCInstancesQuery } from 'services/1c-instances/cInstancesService';
import {
  CInstancesResponseType,
  GetCInstancesPropsType,
} from 'services/1c-instances/cInstancesService.types';

import { RequestBodyType } from './settings1cInstances.utils';

interface UseGet1cInstancesProps {
  debouncedRequestBody: RequestBodyType;
}

const useGet1cInstances = (
  props: UseGet1cInstancesProps,
): {
  cInstances?: CInstancesResponseType;
  isFetching: boolean;
  isLoading: boolean;
} => {
  const { debouncedRequestBody } = props;
  const [getCInstances, { data: cInstances, isFetching, isLoading }] = useLazyGetCInstancesQuery();

  useEffect(() => {
    const { search } = debouncedRequestBody;

    const preparedBody: GetCInstancesPropsType = {
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
    };

    if (debouncedRequestBody.property && debouncedRequestBody.order) {
      preparedBody.sort = [
        { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
      ];
    }

    if (search) {
      preparedBody.filterByNameParam = search;
    }

    getCInstances(preparedBody);
  }, [getCInstances, debouncedRequestBody]);

  return {
    cInstances,
    isFetching,
    isLoading,
  };
};

export default useGet1cInstances;
