import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_INQUIRIES_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { StatementType } from 'types/documents.types';

import s from './DocumentsInquiries.module.scss';
import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, FormResponseType } from './documentsInquiries.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsInquiries = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();

  const { data, isFetching, isLoading } = useGetData({ form });

  const requestBody = Form.useWatch<FormResponseType>([], form);

  const filters = requestBody?.filters;
  console.log(filters);

  const hasFilterApplied: boolean =
    !!filters?.categoryIds?.length || !!filters?.employerIds?.length || !!filters?.statuses?.length;

  const locale = {
    emptyText: () => (
      <Empty title={hasFilterApplied ? 'common_no_records_found' : 'inquiries_not_found'} />
    ),
  };

  const handleOnRow = (record: StatementType) => ({
    onClick: () => navigate(`${DOCUMENTS_INQUIRIES_ROUTE}/${record.id}`),
  });

  const columns = useColumns();

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title total={data?.totalCount}>{t('common_inquiries')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <WithLoader isLoading={isLoading}>
          <FiltersV2.Table<StatementType, FormResponseType>
            dataSource={data?.items}
            total={data?.totalCount}
            columns={columns}
            onRow={handleOnRow}
            className={s.table}
            loading={isFetching}
            rowKey="id"
            locale={locale}
          />
        </WithLoader>
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsInquiries;
