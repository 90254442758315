import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';
import {
  GetEmployersResponse,
  GetSocialTypesResponseType,
} from 'services/social-program/socialProgramApiService.types';

import {
  GetSocialProgramDocumentsResponse,
  GetSocialProgramEmployeeRequirements,
  GetSocialProgramPayoutAmountResponse,
  GetSocialTypesPropsType,
  UpdateSocialProgramDocumentsRequest,
  UpdateSocialProgramEmployeeRequirementsRequest,
  UpdateSocialProgramEmployeeRequirementsResponse,
  UpdateSocialProgramPayoutAmountRequest,
} from './socialProgramCategoryApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { PaginationPropsType } from 'types/pagination.types';

import {
  mapDocuments,
  mapEmployeeRequirements,
  mapEmployers,
  mapPayoutAmount,
  mapSocialTypes,
} from './utils';

const socialProgramCategoryApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSocialProgramEmployeeRequirements: builder.query<
      ReturnType<typeof mapEmployeeRequirements>,
      PaginationPropsType
    >({
      query: (params) => ({
        url: `social-program-employee-requirements${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAM_EMPLOYEE_REQUIREMENTS],
      transformResponse: (resp: GetSocialProgramEmployeeRequirements) =>
        mapEmployeeRequirements(resp.items),
    }),
    updateSocialProgramEmployeeRequirements: builder.mutation<
      UpdateSocialProgramEmployeeRequirementsResponse,
      UpdateSocialProgramEmployeeRequirementsRequest
    >({
      query: (body) => ({
        url: `social-program-employee-requirements`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAM_EMPLOYEE_REQUIREMENTS],
    }),
    getSocialProgramDocuments: builder.query<ReturnType<typeof mapDocuments>, PaginationPropsType>({
      query: (params) => ({
        url: `social-program-documents${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAM_DOCUMENTS],
      transformResponse: (resp: GetSocialProgramDocumentsResponse) => mapDocuments(resp.items),
    }),
    updateSocialProgramDocuments: builder.mutation<
      { id: string },
      UpdateSocialProgramDocumentsRequest
    >({
      query: (body) => ({
        url: `social-program-documents`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAM_DOCUMENTS],
    }),
    getSocialProgramPayoutAmount: builder.query<
      ReturnType<typeof mapPayoutAmount>,
      PaginationPropsType
    >({
      query: (params) => ({
        url: `social-program-payout-amount${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAM_DOCUMENTS_PAYOUT_AMOUNT],
      transformResponse: (resp: GetSocialProgramPayoutAmountResponse) =>
        mapPayoutAmount(resp.items),
    }),
    updateSocialProgramPayoutAmount: builder.mutation<
      { id: string },
      UpdateSocialProgramPayoutAmountRequest
    >({
      query: (body) => ({
        url: `social-program-payout-amount`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SOCIAL_PROGRAM_DOCUMENTS_PAYOUT_AMOUNT],
    }),
    getEmployer: builder.query<ReturnType<typeof mapEmployers>, PaginationPropsType>({
      query: (params) => ({
        url: `employer${prepareQueryParams(params)}`,
      }),
      transformResponse: (resp: GetEmployersResponse) => mapEmployers(resp.content),
    }),
    getSocialTypes: builder.query<ReturnType<typeof mapSocialTypes>, GetSocialTypesPropsType>({
      query: (params) => ({
        url: `kedo/statement${prepareQueryParams(params)}`,
      }),
      transformResponse: (resp: GetSocialTypesResponseType) => mapSocialTypes(resp.items),
    }),
  }),
});

export const {
  useGetSocialProgramEmployeeRequirementsQuery,
  useUpdateSocialProgramEmployeeRequirementsMutation,
  useGetEmployerQuery,
  useGetSocialProgramDocumentsQuery,
  useUpdateSocialProgramDocumentsMutation,
  useGetSocialProgramPayoutAmountQuery,
  useUpdateSocialProgramPayoutAmountMutation,
  useGetSocialTypesQuery,
} = socialProgramCategoryApiService;
