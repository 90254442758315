import { FC } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './LastChange.module.scss';

interface LastChangeProps {
  date?: string | null;
  editorId?: string | null;
  editorName?: string | null;
}

const LastChange: FC<LastChangeProps> = (props) => {
  const { date, editorId, editorName } = props;

  if (!date || !editorId) {
    return <></>;
  }

  const formattedDate = dayjs.utc(date).local().format('DD MMMM YYYY, HH:mm');

  return (
    <Typography.Text type="secondary" className={s.wrapper} size="14">
      <span>{t('common_last_change')}:</span>
      <span>
        <Icon name="calendar" />
        <Typography.Text type="secondary" size="14">
          {formattedDate}
        </Typography.Text>
      </span>
      <span>
        <Icon name="accountCircle" />
        <Typography.Text type="secondary" size="14">
          {t('common_admin_id')}:
        </Typography.Text>
        <Typography.Text type="secondary" size="14">
          {editorId}
        </Typography.Text>
        <Typography.Text type="secondary" size="14">
          {`(${editorName})`}
        </Typography.Text>
      </span>
    </Typography.Text>
  );
};

export default LastChange;
