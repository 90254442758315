import { GetFeedbacksPropsType } from 'services/thank-you/thankYouApiService.types';

import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';
import { TFeedback } from 'types/thankYou.types';

export const MAX_EMPLOYERS_PER_PAGE = 500;

export const fieldsAssociationsForSort: Record<string, string> = {
  sender: 'feedback.author_person_id',
  recipient: 'feedback.recipient_person_id',
  category: 'category',
  date: 'feedback.createDate',
  status: 'status',
  thankText: 'text',
};
export type RequestBody = PaginationPropsType & {
  property?: string;
  order: SORT_ENUM;
  text: string;
};

export const DEFAULT_REQUEST_BODY: RequestBody = {
  page: 1,
  size: 10,
  property: 'date',
  order: SORT_ENUM.DESC,
  text: '',
};

export type ThankYouFiltersType = Pick<
  GetFeedbacksPropsType,
  'authorEmployers' | 'recipientEmployers' | 'status'
> | null;

export const mapFeedBacks = (feedbacks: TFeedback[]) => {
  return feedbacks.map((feedback) => {
    const {
      id,
      authorEmployee,
      authorPerson,
      recipientEmployee,
      recipientPerson,
      text,
      category,
      createDate,
      status,
    } = feedback;

    return {
      key: id,
      sender: {
        firstName: authorPerson.firstName,
        lastName: authorPerson.lastName,
        department: '',
        position: authorEmployee.position,
      },
      recipient: {
        firstName: recipientPerson.firstName,
        lastName: recipientPerson.lastName,
        department: '',
        position: recipientEmployee.position,
      },
      thankText: text,
      status: status,
      category,
      date: createDate,
    };
  });
};
