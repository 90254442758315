import { useMemo } from 'react';

import { t } from 'tools/i18n';
import { formatMilliseconds } from 'utils/formatMilliseconds.utils';
import { truncateText } from 'utils/truncateText.utils';

import { TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { SynclogType } from 'types/synclog.types';

import { CONNECTION_TYPE_LABELS } from '../c-instance-synchronization-modal/cInstanceSynchronizationModal.utils';

const useColumns = (): TableProps<SynclogType>['columns'] => {
  return useMemo(
    () => [
      {
        width: 120,
        title: t('common_date'),
        dataIndex: 'syncDate',
        key: 'syncDate',
        align: 'center',
        sorter: true,
        defaultSortOrder: 'descend',
        render: (date: string) => <TableDate date={date} />,
      },
      {
        width: 180,
        title: t('1c_instance_status'),
        dataIndex: 'success',
        key: 'success',
        sorter: true,
        render: (success: boolean) => (
          <TableStatus status={success ? STATUSES.SUCCESS : STATUSES.ERROR} />
        ),
      },
      {
        title: t('common_data_type'),
        dataIndex: 'syncType',
        key: 'syncType',
        width: 220,
        sorter: true,
        render: (syncType: string) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {t(CONNECTION_TYPE_LABELS[syncType])}
          </Typography.Text>
        ),
      },
      {
        width: 160,
        title: t('common_duration'),
        dataIndex: 'duration',
        key: 'duration',
        align: 'center',
        render: (duration: string) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {formatMilliseconds(duration)}
          </Typography.Text>
        ),
      },
      {
        title: t('common_error_text'),
        dataIndex: 'message',
        key: 'message',
        render: (message: string | null) => (
          <Typography.Text size="12" weight="500" type="secondary">
            {truncateText(message ?? '')}
          </Typography.Text>
        ),
      },
    ],
    [],
  );
};

export default useColumns;
