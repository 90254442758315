import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { Icon, TIconsLiteral, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableText from 'components/table/table-text';

import { NotificationTemplateType } from 'types/notification.types';
import { PersonType } from 'types/person.types';

import s from './NotificationTemplateTable.module.scss';

const useColumns = (): TableProps<NotificationTemplateType>['columns'] => {
  return useMemo(() => {
    return [
      {
        title: t('common_template_name'),
        dataIndex: 'name',
        sorter: true,
        width: '70%',
        render: (name: string, row: NotificationTemplateType) => {
          return (
            <div className={s.imageWrapper}>
              <Icon name={(row.icon as TIconsLiteral) || 'noImage'} />

              <Typography.Text size="12" weight="500">
                {name}
              </Typography.Text>
            </div>
          );
        },
      },
      {
        title: t('common_author'),
        dataIndex: 'creator',
        sorter: true,
        render: (creator: PersonType) => {
          const middleName = creator.middleName ? `${creator.middleName[0]}.` : '';

          return (
            <TableText size="12" weight="500">
              {`${creator.name} ${middleName}`}
            </TableText>
          );
        },
      },
      {
        title: t('common_updated'),
        align: 'center',
        sorter: true,
        dataIndex: 'updatedAt',
        render: (updatedAt: number) => <TableDate date={updatedAt} />,
      },
    ];
  }, []);
};

export default useColumns;
