import { useNavigate } from 'react-router-dom';
import { SURVEYS_ADD_EVENT_ROUTE, SURVEYS_ADD_ONCE_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import useMatchOnceRoute from '../useMatchOnceRoute';

interface Props {
  total: number | undefined;
  title: string;
}
const Header = (props: Props) => {
  const { total, title } = props;

  const navigate = useNavigate();
  const isOnceSurveysRoute = useMatchOnceRoute();

  const handleAdd = () =>
    navigate(isOnceSurveysRoute ? SURVEYS_ADD_ONCE_ROUTE : SURVEYS_ADD_EVENT_ROUTE);

  return (
    <ContentHeader>
      <ContentHeader.Title total={total}>{title}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button leftIcon="plus" onClick={handleAdd}>
          {t('surveys_add')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
