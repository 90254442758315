import { FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { TOP_MANAGEMENT_FORMS_ROUTE } from 'routes/top-management/list';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useAcceptRenewFormMutation,
  useGetRenewTermFormByIdQuery,
} from 'services/renew-term/renewApiService';

import { Button, Flex } from 'gazprom-ui-lib';

import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import DocumentsView from './containers/documents-view/documentsView';
import Header from './containers/header';
import s from './topManagementFormsDetails.module.scss';

const TopManagementFormsDetails: FC = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data: formData, isLoading, isError } = useGetRenewTermFormByIdQuery(id!, { skip: !id });
  const [acceptForm, { isLoading: isAccepting }] = useAcceptRenewFormMutation();

  const handleGoBack = () => {
    navigate(TOP_MANAGEMENT_FORMS_ROUTE);
  };

  const handleAccept = () => {
    if (id) {
      acceptForm(id).then(handleModalAccept);
    }
  };

  const handleModalAccept = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('top_management_form_accept_title'),
      },
      description: {
        children: t('top_management_form_accept_description'),
      },
    },
  });

  const canBeAccepted = formData?.status === 'SENT';

  return (
    <WithOverflow>
      <Header
        updatedAt={formData?.updatedAt}
        createdAt={formData?.createdAt}
        status={formData?.status}
      />
      <WithLoader isLoading={isLoading}>
        <DocumentView isError={isError}>
          <DocumentView.Content>
            <Flex vertical gap="24">
              <DocumentView.Employee employee={formData?.employee} />
              <DocumentsView taskId={id!} files={formData?.files} />
            </Flex>
          </DocumentView.Content>
          <Flex align="center" gap="8">
            {canBeAccepted && (
              <Button
                loading={isAccepting}
                className={s.closeButton}
                onClick={handleAccept}
                fullWidth>
                {t('top_management_form_approve')}
              </Button>
            )}
            <Button type="secondary" className={s.closeButton} onClick={handleGoBack} fullWidth>
              {t('common_close')}
            </Button>
          </Flex>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default TopManagementFormsDetails;
