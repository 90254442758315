import { FC, useEffect, useState } from 'react';

import { useMatch, useNavigate } from 'react-router-dom';
import {
  NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE,
  getNotificationNewsletterWithIdRoute,
  getSystemNotificationNewsletterWithIdRoute,
} from 'routes/notification/list';
import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetNotificationNewslettersQuery } from 'services/notificationV2/notificationV2ApiService';
import { GetNotificationNewslettersPropsType } from 'services/notificationV2/notificationV2ApiService.types';

import { Table } from 'gazprom-ui-lib';

import { NotificationNewsletterType, SENDER_ENUM } from 'types/notification.types';

import s from './NotificationNewsletterTable.module.scss';
import { INITIAL_REQUEST_BODY, RequestBody } from './notificationNewsletterTable.utils';
import useColumns from './useColumns.hook';

interface NotificationNewsletterTableProps {
  pageSize?: number;
  templateId?: string;
  search?: string;
  senderType?: SENDER_ENUM;
}

const NotificationNewsletterTable: FC<NotificationNewsletterTableProps> = (props) => {
  const { pageSize = 10, search, templateId, senderType = SENDER_ENUM.HUMAN } = props;

  const navigate = useNavigate();

  const isSystemNotification = !!useMatch(NOTIFICATION_SYSTEM_NOTIFICATIONS_ROUTE);

  const [requestBody, setRequestBody] = useState<RequestBody>({
    ...INITIAL_REQUEST_BODY,
    size: pageSize,
  });

  const [
    getNotificationTemplates,
    { data: notificationNewslettersData, isFetching: notificationDataIsFetching },
  ] = useLazyGetNotificationNewslettersQuery();

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  useEffect(() => {
    if (typeof search === 'string') {
      setRequestBody((prevState) => ({
        ...prevState,
        page: 1,
        search,
      }));
    }
  }, [search]);

  useEffect(() => {
    const search = debouncedRequestBody.search || undefined;
    const defaultBody: GetNotificationNewslettersPropsType = {
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
      search,
      senderType,
    };

    if (templateId) {
      defaultBody.templateId = templateId;
    }

    if (debouncedRequestBody.property && debouncedRequestBody.order) {
      defaultBody.sort = [
        { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
      ];
    }

    getNotificationTemplates(defaultBody);
  }, [debouncedRequestBody, getNotificationTemplates, senderType, templateId]);

  const columns = useColumns();
  const onRow = ({ id }: NotificationNewsletterType) => ({
    onClick: () => {
      if (isSystemNotification) {
        navigate(getSystemNotificationNewsletterWithIdRoute(id));
      } else {
        navigate(getNotificationNewsletterWithIdRoute(id));
      }
    },
  });

  return (
    <Table
      className={s.table}
      rowKey="id"
      dataSource={notificationNewslettersData?.items}
      columns={columns}
      showSorterTooltip={false}
      onChange={handleTableChange(setRequestBody)}
      onRow={onRow}
      pagination={{
        defaultCurrent: requestBody.page,
        defaultPageSize: requestBody.size,
        total: notificationNewslettersData?.totalCount,
      }}
      loading={notificationDataIsFetching}
    />
  );
};

export default NotificationNewsletterTable;
