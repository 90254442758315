import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useDeleteCInstanceMutation } from 'services/1c-instances/cInstancesService';

import { Icon, Input, InputProps } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import WithLoader from 'containers/wrappers/with-loader';

import s from './Settings1cInstances.module.scss';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_REQUEST_BODY, RequestBodyType } from './settings1cInstances.utils';
import useGet1cInstances from './useGet1cInstances.hook';

const Settings1cInstances = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);
  const [cInstanceToDelete, setCInstanceToDelete] = useState<string[]>([]);
  const [showDeleteErrorModal, setShowDeleteErrorModal] = useState<boolean>(false);

  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);
  const [deleteCInstance, { isLoading: isDeletingInstance }] = useDeleteCInstanceMutation();
  const { cInstances, isFetching, isLoading } = useGet1cInstances({ debouncedRequestBody });

  const clearCInstanceToDelete = () => setCInstanceToDelete([]);

  const handleErrorModalOpenClose = () => {
    setShowDeleteErrorModal((prevState) => !prevState);
  };

  const handleDeleteCInstance = () => {
    const [instance] = cInstanceToDelete;

    deleteCInstance(instance).then((res) => {
      if ('error' in res) {
        handleErrorModalOpenClose();
      }
      clearCInstanceToDelete();
    });
  };

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  useEffect(() => {
    if (state?.requestBody) {
      setRequestBody(state.requestBody);
      navigate(pathname, { replace: true });
    }
  }, [navigate, pathname, state?.requestBody]);

  return (
    <WithLoader isLoading={isLoading}>
      <Header />
      <div className={s.search}>
        <Input
          value={requestBody.search}
          onChange={handleSearchChange}
          size="small"
          prefix={<Icon name="search" />}
          placeholder={t('common_search')}
        />
      </div>
      <Table
        onChange={handleTableChange(setRequestBody)}
        setCInstanceToDelete={setCInstanceToDelete}
        dataSource={cInstances}
        requestBody={requestBody}
        loading={isFetching}
      />
      <RemoveConfirm
        icon="bin"
        buttonIcon="bin"
        isLoading={isDeletingInstance}
        type="danger"
        title={t('common_accept_remove')}
        subtitle={t('1c_instance_delete_description')}
        isOpen={!!cInstanceToDelete.length}
        buttonText={t('common_remove')}
        buttonProps={{ type: 'primary', danger: true, ghost: true }}
        onCancel={clearCInstanceToDelete}
        onConfirm={handleDeleteCInstance}
      />
      <RemoveConfirm
        icon="info"
        type="warning"
        isOpen={showDeleteErrorModal}
        onCancel={handleErrorModalOpenClose}
        title={t('common_error')}
        subtitle={t('1c_instance_delete_error')}
        onConfirm={handleErrorModalOpenClose}
        buttonText={t('common_close')}
        buttonProps={{ type: 'primary', ghost: true }}
      />
    </WithLoader>
  );
};

export default Settings1cInstances;
