import { useContext, useState } from 'react';

import { SaveModalContext } from 'app.utils';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_TELEGRAM_CHANNELS_ROUTE } from 'routes/settings/list';
import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useImportChannelByLinksMutation } from 'services/import/importApiService';
import { ImportChannelPropsType } from 'services/import/importApiService.types';

import { Button, Icon, Input, InputProps } from 'gazprom-ui-lib';

import { TelegramChannelType } from 'types/telegramChannel.types';

import { SETTINGS_TELEGRAM_AUTHORIZATION_TAB } from '../settingsTelegramChannels.utils';
import s from './SettingsTelegram.module.scss';
import Table from './containers/table';
import TableActions from './containers/table-actions';
import { DEFAULT_REQUEST_BODY, RequestBody } from './settingsTelegram.utils';
import useRequest from './useRequest.hook';

const SettingsTelegram = () => {
  const navigate = useNavigate();

  const setModalConfig = useContext(SaveModalContext);

  const [requestBody, setRequestBody] = useState<RequestBody>(DEFAULT_REQUEST_BODY);

  const [selectedTelegramChannels, setSelectedTelegramChannels] = useState<
    Array<TelegramChannelType['id']>
  >([]);

  const { data: telegramChannels, isFetching } = useRequest({ requestBody });

  const [startImport, { isLoading: importIsLoading }] = useImportChannelByLinksMutation();

  const handleClear = () => setSelectedTelegramChannels([]);

  const hasSelectedChannels = !!selectedTelegramChannels?.length;

  const handleAuthorizeClick = () => {
    if (setModalConfig) {
      setModalConfig(null);

      navigate(SETTINGS_TELEGRAM_CHANNELS_ROUTE, {
        state: {
          activeTab: SETTINGS_TELEGRAM_AUTHORIZATION_TAB,
          authRequired: true,
        },
      });
    }
  };

  const handleImport = useHandleRequestWithModal({
    onSuccess: handleClear,
    onError: handleClear,
    onErrorModalConfig: {
      title: {
        children: t('telegram_import_success_title'),
      },
      description: {
        children: t('telegram_import_success_description'),
      },
    },
    onSuccessModalConfig: {
      icon: {
        name: 'error',
        type: 'danger',
      },
      title: {
        children: t('telegram_import_error_title'),
      },
      description: {
        children: t('telegram_import_error_description'),
      },
      actions: (
        <Button onClick={handleAuthorizeClick} rightIcon="arrowRight">
          {t('common_authorize')}
        </Button>
      ),
    },
  });

  const handleStartImport = async (links: ImportChannelPropsType) => {
    await startImport(links).then(handleImport);
  };

  const handleSearch: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: DEFAULT_REQUEST_BODY.page,
    }));
  };

  const filterActions = hasSelectedChannels ? (
    <TableActions
      channels={telegramChannels?.items}
      selectedChannels={selectedTelegramChannels}
      setSelectedChannels={setSelectedTelegramChannels}
      handleStartImport={handleStartImport}
    />
  ) : (
    <Input
      size="small"
      prefix={<Icon name="search" />}
      placeholder={t('common_search')}
      allowClear
      value={requestBody?.search}
      onChange={handleSearch}
    />
  );

  return (
    <div>
      <div className={s.filterActionsWrapper}>{filterActions}</div>
      <Table
        handleStartImport={handleStartImport}
        pagination={{
          current: requestBody.page,
          defaultCurrent: requestBody.page,
          defaultPageSize: requestBody.size,
          total: telegramChannels?.totalCount,
        }}
        importIsLoading={importIsLoading}
        selectedRowKeys={selectedTelegramChannels}
        onSelectedRowKeysChange={setSelectedTelegramChannels}
        telegramChannels={telegramChannels?.items}
        isLoading={isFetching}
        onChange={handleTableChange(setRequestBody)}
      />
    </div>
  );
};

export default SettingsTelegram;
