import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { getDocumentsBusinessTripsWithIdRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { BusinessTripType } from 'types/businessTrip.types';

import s from './DocumentsBusinessTrips.module.scss';
import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, DEFAULT_SORT, FormResponseType } from './documentsBusinessTrips.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsBusinessTrips: FC = () => {
  const [form] = Form.useForm<FormResponseType>();

  const navigate = useNavigate();

  const columns = useColumns();

  const { businessTrips, businessTripsIsFetching } = useGetData({ form });

  const onRow = (request: BusinessTripType) => ({
    onClick: () => navigate(getDocumentsBusinessTripsWithIdRoute(request.id)),
  });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('documents_business_trips')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<BusinessTripType, FormResponseType>
          fallbackSort={DEFAULT_SORT}
          className={s.tableWrapper}
          dataSource={businessTrips?.workTours}
          columns={columns}
          rowKey="id"
          onRow={onRow}
          total={businessTrips?.totalCount}
          loading={businessTripsIsFetching}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsBusinessTrips;
