import { useState } from 'react';

import { t } from 'tools/i18n';

import { Icon, Input, InputProps } from 'gazprom-ui-lib';

import NotificationTemplateTable from 'containers/notification-template-table';

import s from './NotificationTemplate.module.scss';

const NotificationTemplate = () => {
  const [searchName, setSearchName] = useState<string>('');
  const handleSearchChange: InputProps['onChange'] = (e) => {
    setSearchName(e.target.value);
  };

  return (
    <div className={s.wrapper}>
      <div>
        <Input
          allowClear
          size="small"
          value={searchName}
          onChange={handleSearchChange}
          prefix={<Icon name="search" />}
          placeholder={t('common_search')}
        />
      </div>
      <NotificationTemplateTable search={searchName} />
    </div>
  );
};

export default NotificationTemplate;
