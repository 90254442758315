import { Key, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEmployees1cQuery } from 'services/1c/cApiService';
import { setEmployees } from 'slices/employeeGroupsSlice';
import { useAppSelector } from 'store';

import { ButtonProps, InputProps } from 'gazprom-ui-lib';

import { RequestBodyEmployeeType } from 'types/employeeGroups.types';

import Table from '../table';
import { DEFAULT_REQUEST_BODY } from './employees.utils';

const Employees = () => {
  const dispatch = useDispatch();
  const [getEmployees, { data, isFetching }] = useLazyGetEmployees1cQuery();

  const [requestBody, setRequestBody] = useState<RequestBodyEmployeeType>(DEFAULT_REQUEST_BODY);
  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);
  const { employees } = useAppSelector((store) => store.employeeGroupsSlice);

  useEffect(() => {
    const initialRequestBody: RequestBodyEmployeeType = {
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
      fio: '',
    };

    if (debouncedRequestBody.fio) {
      initialRequestBody.fio = debouncedRequestBody.fio;
    }

    getEmployees(initialRequestBody);
  }, [debouncedRequestBody, getEmployees]);

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setRequestBody((prevState) => ({
      ...prevState,
      fio: e.target.value,
      page: DEFAULT_REQUEST_BODY.page,
    }));
  };

  const removeSelection = () => dispatch(setEmployees([]));

  const onChange = (selected: Key[]) => {
    dispatch(setEmployees(selected as string[]));
  };

  const hasSelected = employees.length > 0;

  const buttons: ButtonProps[] = [
    {
      children: t('common_remove_selection'),
      leftIcon: 'delete',
      onClick: removeSelection,
    },
  ];

  const pagination = {
    defaultCurrent: requestBody.page,
    defaultPageSize: requestBody.size,
    total: data?.totalCount,
  };

  return (
    <Table
      tableData={data?.employees ?? []}
      searchValue={requestBody.fio ?? ''}
      handleSearchChange={handleSearchChange}
      rowSelection={{
        selectedRowKeys: employees,
        onChange: onChange,
        preserveSelectedRowKeys: true,
      }}
      hasSelected={hasSelected}
      buttons={buttons}
      loading={isFetching}
      pagination={pagination}
      setRequestBody={setRequestBody}
    />
  );
};

export default Employees;
