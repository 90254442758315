import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import { formatComplexStatusesToFlat } from 'utils/filter.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetBusinessTripsQuery } from 'services/1c/work-tour/workTourApiService';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './documentsBusinessTrips.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const formData = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  const { range, order, property, filters } = debouncedFormData ?? {};

  const dateFrom = range?.[0] ? dayjs.utc(range[0]).format() : undefined;
  const dateTo = range?.[1] ? dayjs.utc(range[1]).format() : undefined;
  const sort = order && property ? [{ order, property: `work_tour.${property}` }] : undefined;
  const currentKedoStatus = formatComplexStatusesToFlat(filters?.status);

  const { data: businessTrips, isFetching: businessTripsIsFetching } = useGetBusinessTripsQuery(
    debouncedFormData?.size
      ? {
          sort,
          dateFrom,
          dateTo,
          currentKedoStatus,
          fio: debouncedFormData.search || undefined,
          page: debouncedFormData.page,
          size: debouncedFormData.size,
        }
      : skipToken,
  );

  return {
    businessTrips,
    businessTripsIsFetching: businessTripsIsFetching || isDebouncing,
  };
};

export default useGetData;
