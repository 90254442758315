import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getTimezone } from 'utils/date.utils';

import { useDownloadAssignmentsReportMutation } from 'services/1c/report/reportApiService';
import { DownloadAssignmentsReportPropsType } from 'services/1c/report/reportApiService.types';

import { Button, Flex, Form } from 'gazprom-ui-lib';

import FiltersWrapper from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { TASK_ASSIGNMENT_TYPE_ENUM } from 'types/taskAssignment.types';

import { FormResponseType } from '../../documentsSigns.utils';

interface Props {
  hideSearch?: boolean;
}

const Filters = (props: Props) => {
  const { hideSearch = false } = props;
  const form = Form.useFormInstance<FormResponseType>();

  const [downloadAssignments, { isLoading }] = useDownloadAssignmentsReportMutation();

  const handleDownload = () => {
    const { filters, range } = form.getFieldsValue();
    console.log(form.getFieldsValue());

    const downloadRequestBody: DownloadAssignmentsReportPropsType = {
      filename: `Список документов на подпись ${dayjs().format()}`,
      timeZone: getTimezone(),
      assignmentFilterParam: {
        types: [
          TASK_ASSIGNMENT_TYPE_ENUM.EMPLOYMENT_SIGN_HEAD,
          TASK_ASSIGNMENT_TYPE_ENUM.SIGN_HEAD,
        ],
      },
    };

    if (range) {
      downloadRequestBody.assignmentFilterParam.from = dayjs.utc(range[0]).format();
      downloadRequestBody.assignmentFilterParam.to = dayjs.utc(range[1]).format();
    }
    if (filters?.statuses?.length) {
      downloadRequestBody.assignmentFilterParam.statuses = filters.statuses;
    }
    if (filters?.employerIds?.length) {
      downloadRequestBody.assignmentFilterParam.employerIds = filters.employerIds;
    }
    downloadAssignments(downloadRequestBody);
  };

  const statuses = [
    {
      label: t('common_of_sign'),
      value: 'pending',
    },
    {
      label: t('common_executed'),
      value: 'executed',
    },
  ];

  return (
    <FiltersV2.TableFilters>
      {!hideSearch && <FiltersV2.Search />}
      <Flex align="center" gap="8">
        <FiltersV2.RangePicker />
        <Button
          size="small"
          type="link"
          leftIcon="download"
          onClick={handleDownload}
          loading={isLoading}>
          {t('common_download_report')}
        </Button>
      </Flex>
      <FiltersWrapper
        overlay={
          <FilterCustomWrapper>
            <FilterHeader valueKey="statuses">{t('common_status')}</FilterHeader>
            <FilterCheckboxGroup usePadding={false} valueKey="statuses" options={statuses} />
          </FilterCustomWrapper>
        }
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
