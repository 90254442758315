export const TOP_MANAGEMENT_ROUTE = '/top-management';

export const TOP_MANAGEMENT_POSITIONS_DIRECTORY_ROUTE = `${TOP_MANAGEMENT_ROUTE}/positions-directory`;
export const TOP_MANAGEMENT_POSITIONS_DIRECTORY_DETAILS_ROUTE = `${TOP_MANAGEMENT_POSITIONS_DIRECTORY_ROUTE}/:employerId`;

export const TOP_MANAGEMENT_FORMS_ROUTE = `${TOP_MANAGEMENT_ROUTE}/forms`;
export const TOP_MANAGEMENT_FORMS_DETAILS_ROUTE = `${TOP_MANAGEMENT_FORMS_ROUTE}/:id`;

export const getTopManagementPositionsDirectoryDetailsRoute = (employerId: string) =>
  `${TOP_MANAGEMENT_POSITIONS_DIRECTORY_ROUTE}/${employerId}`;

export const getTopManagementFormsDetailsRoute = (id: string) =>
  `${TOP_MANAGEMENT_FORMS_ROUTE}/${id}`;
