import { t } from 'tools/i18n';

import { GetTaskByIdResponseType } from 'services/1c/cApiService.types';

import { Button, Flex } from 'gazprom-ui-lib';

import { DOCUMENT_VIEW_PERSON_ENUM, PersonComponentParamsType } from 'types/documents.types';

export const findKey =
  (personId: string) => (sign: GetTaskByIdResponseType['files']['0']['signs'][0]) =>
    sign.key.includes(personId);

export const getComponentsParams = ({
  onClick,
  onReject,
  rejected,
  signed,
}: {
  onClick: () => void;
  onReject?: () => void;
  rejected?: boolean;
  signed?: boolean;
}): PersonComponentParamsType | undefined => {
  if (signed || rejected) {
    return {
      button: rejected ? (
        <Button leftIcon="delete" disabled>
          {t('common_rejected')}
        </Button>
      ) : (
        <Button leftIcon="ok" disabled>
          {t('common_signed')}
        </Button>
      ),
      isMe: true,
      icon: 'ok',
      type: DOCUMENT_VIEW_PERSON_ENUM.SUCCESS,
    };
  }

  return {
    button: (
      <Flex gap="8">
        <Button onClick={onClick} leftIcon="signature">
          {t('common_sign')}
        </Button>
        {onReject && (
          <Button onClick={onReject} danger type="secondary">
            {t('common_reject')}
          </Button>
        )}
      </Flex>
    ),
    isMe: true,
    icon: 'time',
    type: DOCUMENT_VIEW_PERSON_ENUM.WARNING,
  };
};

export const getArchiveUrl = (id: string) =>
  `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-file/archive?kedoTaskId=${id}&full=true`;
export const getFileUrl = (id: string, fileKey: string) =>
  `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-file/task/${id}/download?fileKey=${fileKey}`;
export const archiveName = 'archive.zip';
