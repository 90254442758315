import { FC } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { useLazyDownloadApprovalMapReportQuery } from 'services/1c/report/reportApiService';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import { BusinessTripApprovalItemType, BusinessTripType } from 'types/businessTrip.types';

import s from './ApprovalHistory.module.scss';
import ApprovalCycle from './containers/approval-cycle';
import Table from './containers/table';

interface Props {
  workTourId?: string;
  orderDate?: string;
  approvalMap?: BusinessTripType['approvalMap'];
  numberDoc?: string;
}

const ApprovalHistory: FC<Props> = (props) => {
  const { workTourId, orderDate, numberDoc, approvalMap = {} } = props;
  const [downloadApprovalMap, { isLoading }] = useLazyDownloadApprovalMapReportQuery();

  const orderDateFormatted = dayjs(orderDate).format('YYYY-MM-DD');

  const isSingleCycle = !!approvalMap && Object.keys(approvalMap).length === 1;

  const [[_, approveItems]] = approvalMap ? Object.entries(approvalMap) : [];

  const handleClick = () => {
    if (workTourId) {
      downloadApprovalMap({
        workTourId,
        filename: t('documents_business_trip_approval_list_download_template'),
      });
    }
  };

  const renderApprovalItem = (
    [cycleNumber, approvals]: [cycleNumber: string, approvals: BusinessTripApprovalItemType[]],
    index: number,
  ) => (
    <ApprovalCycle
      key={cycleNumber}
      cycleNumber={cycleNumber}
      dataSource={approvals}
      expandedByDefault={index === 0}
    />
  );

  return (
    <Flex vertical>
      <Flex align="center" gap="16" className={s.header}>
        <Button loading={isLoading} leftIcon="download" onClick={handleClick}>
          {t('documents_statements_download_approval_list')}
        </Button>
        <Flex align="center" gap="8">
          <Icon name="info" color="var(--color-primary-6)" />
          <Typography.Text type="secondary" size="14">
            {t('documents_business_trip_approval_history_title', {
              orderNumber: numberDoc,
              orderDate: orderDateFormatted,
            })}
          </Typography.Text>
        </Flex>
      </Flex>
      {isSingleCycle ? (
        <Table dataSource={approveItems} />
      ) : (
        Object.entries(approvalMap).map(renderApprovalItem)
      )}
    </Flex>
  );
};

export default ApprovalHistory;
