import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { ACCESS_CONTROL_USERS_ROUTE, getUserRoleWithCodeRoute } from 'routes/access-control/list';
import { t } from 'tools/i18n';

import { Button, ColumnsType } from 'gazprom-ui-lib';

import TableText from 'components/table/table-text';

import { RoleType } from 'types/roles.types';

import { createMediaDownloadUrl } from '../../../../utils/createMediaUrl.utils';
import s from './AccessControlRolesTable.module.scss';

const useColumns = (): ColumnsType<RoleType> => {
  const navigate = useNavigate();

  return useMemo(
    () => [
      {
        title: t('common_role'),
        dataIndex: 'code',
        key: 'code',
        render: (text: string) => {
          return (
            <TableText size="14" weight="500" className={s.maxWidth}>
              {text}
            </TableText>
          );
        },
      },
      {
        title: t('common_name'),
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
          return (
            <TableText size="14" weight="500" className={s.maxWidth} type="secondary">
              {text}
            </TableText>
          );
        },
      },
      {
        title: t('common_description'),
        dataIndex: 'description',
        key: 'description',
        render: (text: string) => {
          return (
            <TableText size="14" className={s.maxWidth} type="secondary">
              {text ?? '-'}
            </TableText>
          );
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (_: void, row: RoleType) => {
          return (
            <div className={s.actions}>
              <Button
                leftIcon="groupsFilled"
                ghost
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(ACCESS_CONTROL_USERS_ROUTE, {
                    state: {
                      role: row.code,
                    },
                  });
                }}>
                {t('common_users')}
              </Button>
              <Button
                leftIcon="download"
                ghost
                disabled={!row.instruction}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();

                  if (row.instruction)
                    window.open(createMediaDownloadUrl(row.instruction?.fileKey));
                }}>
                {t('common_download_instruction')}
              </Button>
              <Button
                leftIcon="edit"
                ghost
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(getUserRoleWithCodeRoute(row.code));
                }}>
                {t('common_edit_short')}
              </Button>
            </div>
          );
        },
      },
    ],
    [navigate],
  );
};

export default useColumns;
