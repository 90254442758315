import { t } from 'tools/i18n';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

import useDocumentsFilters from 'pages/documents/useDocumentsFilters';

const useFilterItemsHook = () => {
  const {
    employersResult,
    employersSearchHandler,
    positionsResult,
    positionsSearchHandler,
    divisionsResult,
    divisionsSearchHandler,
  } = useDocumentsFilters({ useDivisionFilter: true, usePositionFilter: true });

  return [
    {
      key: 0,
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerIds"
          optionFilterProp="value"
          options={employersResult.employersOptions}
          showSearch
          searchProps={{
            placeholder: t('common_organization_search'),
            onChange: employersSearchHandler,
          }}
        />
      ),
    },
    {
      key: 1,
      label: <FilterHeader valueKey="divisionIds">{t('common_division')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="divisionIds"
          optionFilterProp="value"
          options={divisionsResult.data}
          showSearch
          searchProps={{
            placeholder: t('common_division'),
            onChange: divisionsSearchHandler,
          }}
        />
      ),
    },
    {
      key: 2,
      label: (
        <FilterHeader valueKey="positionIds">{t('employee_group_column_position')}</FilterHeader>
      ),
      children: (
        <FilterCheckboxGroup
          valueKey="positionIds"
          optionFilterProp="value"
          options={positionsResult.data}
          showSearch
          searchProps={{
            placeholder: t('employee_group_column_position'),
            onChange: positionsSearchHandler,
          }}
        />
      ),
    },
  ];
};

export default useFilterItemsHook;
