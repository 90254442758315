import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';

import { useGetEmployersListQuery } from 'services/employer/employerApiService';
import { GetEmployersResponse } from 'services/employer/employerApiService.types';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

import { MAX_EMPLOYERS_PER_PAGE } from '../thank-you.utils';

const useFilterItems = () => {
  const [authorEmployer, setAuthorEmployer] = useState<string>();
  const [recipientEmployer, setRecipientEmployer] = useState<string>();

  const { employersOptions: authorEmployers } = useGetEmployersListQuery(
    { page: 1, size: MAX_EMPLOYERS_PER_PAGE, name: authorEmployer },
    { selectFromResult },
  );
  const { employersOptions: recipientEmployers } = useGetEmployersListQuery(
    { page: 1, size: MAX_EMPLOYERS_PER_PAGE, name: recipientEmployer },
    { selectFromResult },
  );

  return useMemo(
    () => [
      {
        key: '0',
        label: (
          <FilterHeader valueKey="authorEmployers">{t('thank_you_author_employers')}</FilterHeader>
        ),
        children: (
          <FilterCheckboxGroup
            valueKey="authorEmployers"
            options={authorEmployers}
            showSearch
            searchProps={{
              placeholder: t('thank_you_author_employers'),
              value: authorEmployer,
              onChange: (e) => setAuthorEmployer(e.target.value || undefined),
            }}
          />
        ),
      },
      {
        key: '1',
        label: (
          <FilterHeader valueKey="recipientEmployers">
            {t('thank_you_recipient_employers')}
          </FilterHeader>
        ),
        children: (
          <FilterCheckboxGroup
            valueKey="recipientEmployers"
            options={recipientEmployers}
            showSearch
            searchProps={{
              placeholder: t('thank_you_recipient_employers'),
              value: recipientEmployer,
              onChange: (e) => setRecipientEmployer(e.target.value || undefined),
            }}
          />
        ),
      },
    ],
    [authorEmployer, authorEmployers, recipientEmployer, recipientEmployers],
  );
};

const selectFromResult = ({ data }: { data?: GetEmployersResponse }) => {
  const employersOptionsSet = new Set<{ label: string; value: string }>();
  data?.content.forEach(({ name }) => employersOptionsSet.add({ value: name, label: name }));

  return {
    employersOptions: Array.from(employersOptionsSet),
  };
};

export default useFilterItems;
