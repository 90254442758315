import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Icon, Input, InputProps } from 'gazprom-ui-lib';

import CompanyFilters from 'containers/company-filters';

import s from './SearchWithFilters.module.scss';

interface EmployeeGroupsFiltersProps extends PropsWithChildren {
  search?: string;
  handleSearchChange?: InputProps['onChange'];
  handleCompanyChange?: (e: string) => void;
  handleDivisionChange?: (e: string) => void;
  handleStaffChange?: (e: string) => void;
  company?: string | null;
  staff?: string | null;
  division?: string | null;
  showSelects?: boolean;
  showSearch?: boolean;
  searchPlaceholder?: string;
  className?: string;
}

const SearchWithFilters: FC<EmployeeGroupsFiltersProps> = (props) => {
  const {
    search,
    handleSearchChange,
    company,
    staff,
    division,
    handleCompanyChange,
    handleStaffChange,
    handleDivisionChange,
    showSelects = true,
    showSearch = true,
    searchPlaceholder = t('common_search_by_credentials'),
    children,
    className,
  } = props;

  return (
    <div className={cn(s.filters, className)}>
      {showSearch && (
        <Input
          allowClear
          value={search}
          size="small"
          onChange={handleSearchChange}
          prefix={<Icon name="search" />}
          placeholder={searchPlaceholder}
        />
      )}
      {showSelects && (
        <CompanyFilters
          company={company}
          position={staff}
          division={division}
          handleCompanyChange={handleCompanyChange}
          handleStaffChange={handleStaffChange}
          handleDivisionChange={handleDivisionChange}
        />
      )}
      {children}
    </div>
  );
};
export default SearchWithFilters;
