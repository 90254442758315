import { useNavigate } from 'react-router-dom';
import { getHandbookWithIdRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { Button, ButtonProps, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import { HandbookType } from 'types/guide.types';

const useColumns = (): TableProps<HandbookType>['columns'] => {
  const navigate = useNavigate();

  const handleEdit =
    (id: string): ButtonProps['onClick'] =>
    (e) => {
      e.stopPropagation();
      navigate(getHandbookWithIdRoute(id));
    };

  const fileInstructionRenderer = (fileInstruction: HandbookType['fileInstruction']) => {
    return (
      !!fileInstruction && (
        <Icon name="radar" color="var(--color-primary-6)" height={18} width={18} />
      )
    );
  };

  const publishRenderer = (isPublish: HandbookType['isPublish']) => {
    return (
      <Icon
        name="okFilled"
        color={isPublish ? 'var(--color-success)' : 'var(--color-placeholder-25)'}
        height={18}
        width={18}
      />
    );
  };

  const actionsRenderer = (id: string) => {
    return (
      <Button leftIcon="edit" type="link" size="small" onClick={handleEdit(id)}>
        {t('common_edit_short')}
      </Button>
    );
  };

  return [
    {
      title: t('guide_handbook_name'),
      dataIndex: 'name',
      sorter: true,
      render: (name: string) => {
        return <Typography.Title level={5}>{name}</Typography.Title>;
      },
    },
    {
      title: t('common_coordinates'),
      dataIndex: 'coordinates',
      sorter: true,
      render: (coordinates: HandbookType['coordinates']) => {
        return (
          <Typography.Text
            size="10"
            weight="500"
            type="secondary">{`${coordinates.x}, ${coordinates.y}`}</Typography.Text>
        );
      },
    },
    {
      title: t('common_map'),
      dataIndex: 'fileMap',
      width: 100,
      sorter: true,
      align: 'center',
      render: (fileMap: HandbookType['fileMap']) => {
        return (
          !!fileMap && <Icon name="map" color="var(--color-primary-6)" height={18} width={18} />
        );
      },
    },
    {
      title: t('common_memo'),
      dataIndex: 'fileInstruction',
      width: 100,
      sorter: true,
      align: 'center',
      render: fileInstructionRenderer,
    },
    {
      title: t('common_publication'),
      dataIndex: 'isPublish',
      sorter: true,
      width: 100,
      align: 'center',
      render: publishRenderer,
    },
    {
      width: 240,
      dataIndex: 'id',
      render: actionsRenderer,
    },
  ];
};
export default useColumns;
