import { useNavigate } from 'react-router-dom';
import { getDocumentsRecruitWithIdRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { RecruitType } from 'types/recruit.types';

import s from './DocumentsRecruit.module.scss';
import { DEFAULT_FORM_STATE, DEFAULT_SORT, FormResponseType } from './documentsRecruits.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsRecruit = () => {
  const [form] = Form.useForm<FormResponseType>();
  const columns = useColumns();
  const navigate = useNavigate();
  const { recruits, recruitsIsFetching } = useGetData({ form });

  const onRow = (request: RecruitType) => ({
    onClick: () => navigate(getDocumentsRecruitWithIdRoute(request.id)),
  });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('common_recruit')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <FiltersV2.Table<RecruitType, FormResponseType>
          fallbackSort={DEFAULT_SORT}
          className={s.tableWrapper}
          dataSource={recruits?.items}
          columns={columns}
          rowKey="id"
          onRow={onRow}
          total={recruits?.totalCount}
          loading={recruitsIsFetching}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsRecruit;
