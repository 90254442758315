import { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { formRules } from 'utils/formRules';

import { Button, Flex, Form, Select } from 'gazprom-ui-lib';

import s from './MultiTagsSelect.module.scss';

interface MultiTagsSelectProps {
  title: string;
  name: string;
  placeholder: string;
  options: { value: string; label: string }[];
  buttonText?: string;
  initialValues?: string[];
  ModalComponent?: FC<{ open: boolean; onClose: () => void; onFinish: (id: string) => void }>;
  className?: string;
  isRequired?: boolean;
}

const MultiTagsSelect: FC<MultiTagsSelectProps> = (props) => {
  const {
    title,
    name,
    placeholder,
    options,
    initialValues,
    buttonText,
    ModalComponent,
    className,
    isRequired = true,
  } = props;
  const [isModalActive, setIsModalActive] = useState(false);
  const form = Form.useFormInstance();
  const rules = isRequired ? [formRules.required] : [];

  useEffect(() => {
    initialValues && form.setFieldValue(name, initialValues);
  }, [form, initialValues, name]);

  const onFinishHandler = (id: string) => {
    const formValue = form.getFieldValue(name) ?? [];
    const newFormValue = [...formValue, id];
    form.setFieldValue(name, newFormValue);
    setIsModalActive(false);
  };

  return (
    <>
      <Flex vertical gap="8" className={cn(s.item, className)} align="flex-start" fullWidth>
        <Form.Item name={name} rules={rules} label={title}>
          <Select
            placeholder={placeholder}
            showSearch
            mode="multiple"
            options={options}
            filterOption
            optionFilterProp="label"
          />
        </Form.Item>
        {buttonText && (
          <Button
            type="link"
            leftIcon="plus"
            className={s.button}
            onClick={() => setIsModalActive(true)}>
            {buttonText}
          </Button>
        )}
      </Flex>
      {ModalComponent && (
        <ModalComponent
          open={isModalActive}
          onClose={() => setIsModalActive(false)}
          onFinish={onFinishHandler}
        />
      )}
    </>
  );
};

export default MultiTagsSelect;
