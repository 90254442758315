import { FC } from 'react';

import dayjs from 'dayjs';

import { Typography } from 'gazprom-ui-lib';

import s from './TableDate.module.scss';

interface TableDateProps {
  date?: string | number | null;
}

const TableDate: FC<TableDateProps> = (props) => {
  const { date } = props;

  if (!date) {
    return <></>;
  }

  const isTodayDate = dayjs.utc(date).local().isToday();

  if (isTodayDate) {
    const parsedDate = dayjs.utc(date).local().format('Сегодня в HH:mm');

    return (
      <Typography.Text size="12" weight="500">
        {parsedDate}
      </Typography.Text>
    );
  }

  const parsedDate = dayjs.utc(date).local().format('DD.MM.YYYY');
  const parsedTime = dayjs.utc(date).local().format('HH:mm');

  return (
    <div className={s.container}>
      <Typography.Text size="12" weight="500">
        {parsedDate}
      </Typography.Text>
      <Typography.Text size="10" type="secondary">
        {parsedTime}
      </Typography.Text>
    </div>
  );
};

export default TableDate;
