import { useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  useCreateHandbookInstructionsMutation,
  useDeleteHandbookInstructionsMutation,
  useUpdateHandbookInstructionsMutation,
} from 'services/settlement/settlementApiService';
import {
  GetHandbookInstructionsResponseType,
  UpdateHandbookInstructionsPropsType,
} from 'services/settlement/settlementApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import {
  HANDBOOK_INSTRUCTION_ENUM,
  HandbookInstructionResponseType,
} from 'containers/handbook-instruction-form/handbookInstructionForm.utils';

import { HandbookInstructionType } from 'types/guide.types';

interface UseActionsHookProps {
  handbookInstructions?: GetHandbookInstructionsResponseType;
}

const useActionsHook = (props: UseActionsHookProps) => {
  const { handbookInstructions } = props;

  const params = useParams<{ handbookId: string }>();

  const [deleteConfig, setDeleteConfig] = useState<null | {
    instructions: HandbookInstructionType[];
    subtitle: string;
  }>(null);

  const [createInstruction, { isLoading: createAddIsFetching }] =
    useCreateHandbookInstructionsMutation();
  const [updateInstruction, { isLoading: updateIsFetching }] =
    useUpdateHandbookInstructionsMutation();

  const [deleteInstructions, { isLoading: deleteIsFetching }] =
    useDeleteHandbookInstructionsMutation();

  const onSuccess = () => setDeleteConfig(null);

  const handleDelete = () => {
    if (deleteConfig) {
      const instructionsToDelete = deleteConfig.instructions.map((i) => i.id);

      deleteInstructions(instructionsToDelete).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleCategoryUpdate = (
    formResponse: HandbookInstructionResponseType,
    categoryId: string,
    initialInstruction: HandbookInstructionType,
  ) => {
    if (params.handbookId) {
      const requestBody: UpdateHandbookInstructionsPropsType = {
        id: initialInstruction.id,
        num: initialInstruction.num,
        settlementId: params.handbookId,
        categoryId,
        name: formResponse[HANDBOOK_INSTRUCTION_ENUM.TITLE],
        schedule: formResponse[HANDBOOK_INSTRUCTION_ENUM.OPERATION_MODE],
        location: formResponse[HANDBOOK_INSTRUCTION_ENUM.LOCATION],
        department: formResponse[HANDBOOK_INSTRUCTION_ENUM.SERVICE],
      };

      const filteredPhones = formResponse[HANDBOOK_INSTRUCTION_ENUM.PHONE]?.filter(
        (contact) =>
          contact[HANDBOOK_INSTRUCTION_ENUM.PHONE_INNER] ||
          contact[HANDBOOK_INSTRUCTION_ENUM.PHONE_OUTER],
      );

      if (filteredPhones?.length) {
        requestBody.contacts = filteredPhones.map((phone, index) => ({
          num: index,
          phone: phone[HANDBOOK_INSTRUCTION_ENUM.PHONE_OUTER],
          internalPhone: phone[HANDBOOK_INSTRUCTION_ENUM.PHONE_INNER],
          email: formResponse[HANDBOOK_INSTRUCTION_ENUM.EMAIL] ?? '',
        }));
      } else {
        requestBody.contacts = [
          { num: 0, email: formResponse[HANDBOOK_INSTRUCTION_ENUM.EMAIL] ?? '' },
        ];
      }

      updateInstruction(requestBody);
    }
  };

  const handleAddCategory = (e: { label: string; value: string }) => {
    if (params.handbookId) {
      createInstruction({
        settlementId: params.handbookId,
        categoryId: e.value,
        schedule: '',
        location: '',
        department: '',
        name: 'Этап 1',
        num: 2,
        contacts: [],
      });
    }
  };
  const handleAddStep = (categoryId: string) => {
    if (params.handbookId && handbookInstructions) {
      const selectedCategory = handbookInstructions.find(
        (instruction) => instruction.category.id === categoryId,
      );
      const maxNumber = (selectedCategory?.instructions ?? []).reduce((prev, curr) => {
        if (prev < curr.num) {
          return curr.num;
        }
        return prev;
      }, 0);
      const instructionsLength = selectedCategory?.instructions?.length ?? 0;

      createInstruction({
        settlementId: params.handbookId,
        categoryId,
        schedule: '',
        location: '',
        department: '',
        name: `Этап ${instructionsLength + 1}`,
        num: maxNumber + 1,
        contacts: [],
      });
    }
  };

  return {
    handleDelete,
    handleCategoryUpdate,
    handleAddCategory,
    handleAddStep,
    setDeleteConfig,
    deleteConfig,
    isFetching: createAddIsFetching || updateIsFetching || deleteIsFetching,
  };
};

export default useActionsHook;
