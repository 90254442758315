import { userManager } from '../../utils/keycloak';

export const checkExtension = () => {
  return !!window.isCryptoProExtensionLoaded;
};

export const checkPlugin = async () => {
  try {
    const oAbout = await window.cadesplugin.CreateObjectAsync('CAdESCOM.About');
    const pluginVersion = await oAbout.PluginVersion;
    const versionString = await pluginVersion.toString();

    return !!versionString;
  } catch (e) {
    return false;
  }
};

export const checkCryptoProvider = async () => {
  try {
    const oAbout = await window.cadesplugin.CreateObjectAsync('CAdESCOM.About');
    await oAbout.CSPName(80);

    return true;
  } catch (err) {
    return false;
  }
};

export const signFile = async (certName: string, _base64Data: string) => {
  const header = ';base64,';
  const base64Data = _base64Data.slice(_base64Data.indexOf(header) + header.length);

  const oStore = await window.cadesplugin.CreateObjectAsync('CAdESCOM.Store');
  await oStore.Open(
    window.cadesplugin.CAPICOM_CURRENT_USER_STORE,
    window.cadesplugin.CAPICOM_MY_STORE,
    window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
  );

  const oStoreCerts = await oStore.Certificates;
  const certCnt = await oStoreCerts.Count;

  let oCertificate;

  for (let i = 1; i <= certCnt; i++) {
    try {
      const cert = await oStoreCerts.Item(i);
      const subjectName = await cert.SubjectName;
      const validFrom = new Date(await cert.ValidFromDate);
      const validTo = new Date(await cert.ValidToDate);

      if (`${validFrom.toString()}.${validTo.toString()}.${subjectName}` === certName) {
        oCertificate = cert;
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!oCertificate) {
    return;
  }

  const oSigner = await window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
  await oSigner.propset_Certificate(oCertificate);
  await oSigner.propset_CheckCertificate(true);

  const oSignedData = await window.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
  await oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);
  await oSignedData.propset_Content(base64Data);

  console.log('pre sign');
  try {
    return await oSignedData.SignCades(oSigner, window.cadesplugin.CADESCOM_CADES_BES, true);
  } catch (err) {
    console.log(err);
    throw new Error();
  }
};

const checkSubjectName = (subjectName: string) => {
  let checkOgrn = false,
    checkO = false,
    checkOU = false,
    checkT = false;

  subjectName.split(', ').forEach((item) => {
    const [key, value] = item.split('=');
    if (value) {
      if (key === 'OGRN') {
        checkOgrn = true;
      } else if (key === 'O') {
        checkO = true;
      } else if (key === 'OU') {
        checkOU = true;
      } else if (key === 'T') {
        checkT = true;
      }
    }
  });

  return checkOgrn && checkO && checkOU && checkT;
};

export const getCertificates = async () => {
  const oStore = await window.cadesplugin.CreateObjectAsync('CAdESCOM.store');

  try {
    await oStore.Open();
  } catch (ex) {
    console.log(ex);
  }

  const oStoreCertificates = await oStore.Certificates;
  const certCnt = await oStoreCertificates.Count;

  let certs = [];

  if (certCnt === 0) {
    throw new Error();
  }

  for (let i = 1; i <= certCnt; i++) {
    try {
      const cert = await oStoreCertificates.Item(i);
      const serialNumber = await cert.SerialNumber;
      const validFrom = new Date(await cert.ValidFromDate);
      const validTo = new Date(await cert.ValidToDate);
      const subjectName = await cert.SubjectName;
      const issuerName = await cert.IssuerName;

      const pubKey = await cert.PublicKey();
      const algo = await pubKey.Algorithm;
      const algoName = await algo.FriendlyName;

      let subjectNameIsValid = checkSubjectName(subjectName);

      let valid = true;

      if (process.env.false?.trim() !== 'false') {
        valid =
          validFrom.getTime() < Date.now() &&
          subjectNameIsValid &&
          validTo.getTime() > Date.now() &&
          !!serialNumber &&
          !!algoName;
      }

      certs.push({
        validFrom,
        validTo,
        subjectName,
        issuerName,
        algoName,
        valid,
      });
    } catch (ex) {
      return;
    }
  }

  return certs;
};

export const handleDownloadAsBlob = async (id: string, fileKey: string) => {
  const user = await userManager.getUser();
  const token = user?.access_token as string;

  return fetch(
    `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-file/assignment/${id}/download?fileKey=${fileKey}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then((res) => res.blob());
};
