import { useEffect } from 'react';

import dayjs from 'dayjs';
import { formatComplexStatusesToFlat } from 'utils/filter.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetStatementsQuery } from 'services/1c/cApiService';
import { GetStatementsRequestType } from 'services/1c/cApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import { FormResponseType } from './documentsStatements.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const [getStatements, { data, isFetching }] = useLazyGetStatementsQuery();

  const formData: FormResponseType | undefined = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  useEffect(() => {
    if (debouncedFormData?.size) {
      const { filters, search, range, page, size, property, order } = debouncedFormData;

      const modifiedStatuses = formatComplexStatusesToFlat(filters?.statuses);

      const initialRequestBody: GetStatementsRequestType = {
        page,
        size,
        search: search,
        statuses: modifiedStatuses,
        categoryIds: filters?.categoryIds,
        employerIds: filters?.employerIds,
        type: [KEDO_TYPE_ENUM.BPMN],
      };

      if (range) {
        initialRequestBody.from = dayjs.utc(range[0]).format();
        initialRequestBody.to = dayjs.utc(range[1]).format();
      }

      if (property && order) {
        initialRequestBody.sort = [
          {
            order,
            property: `kedo_task.${property}`,
          },
        ];
      }

      getStatements(initialRequestBody);
    }
  }, [getStatements, debouncedFormData]);

  return {
    data,
    isFetching: isDebouncing || isFetching,
  };
};

export default useGetData;
