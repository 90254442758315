import { t } from 'tools/i18n';

import { useGetEmployerQuery } from 'services/social-program-category/socialProgramCategoryApiService';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { FORM_ENUM } from '../../faqQuestionForm.utils';
import s from './Organization.module.scss';

const Organization = () => {
  const { data: employersOptions, isLoading: isEmployersOptionsLoading } = useGetEmployerQuery({
    page: 1,
    size: 500,
  });

  return (
    <Form.Item
      name={FORM_ENUM.ORGANIZATION}
      label={t('common_organization')}
      extra={
        <Typography.Text className={s.selectHint} size="12" type="secondary">
          {t('faq_organization_select_hint')}
        </Typography.Text>
      }>
      <Select
        allowClear
        placeholder={t('common_choose_organization_from_list')}
        options={employersOptions}
        loading={isEmployersOptionsLoading}
      />
    </Form.Item>
  );
};

export default Organization;
