import { FC, ReactNode } from 'react';

import { t } from 'tools/i18n';
import { getFiltersCount } from 'utils/filter.utils';

import { GetFeedbacksPropsType } from 'services/thank-you/thankYouApiService.types';

import Filters from 'containers/filters';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { THANK_YOU_STATUS_ENUM } from 'types/thankYou.types';

import s from './ThankYouFilters.module.scss';
import useFilterItems from './useFilterItems.hook';

interface ThankYouFiltersProps<
  T = Pick<GetFeedbacksPropsType, 'authorEmployers' | 'recipientEmployers' | 'status'> | null,
> {
  actions: ReactNode;
  filters: T;
  onFiltersSubmit: (value: T) => void;
}

const ThankYouFilters: FC<ThankYouFiltersProps> = (props) => {
  const { actions, filters, onFiltersSubmit } = props;

  const filterItems = useFilterItems();

  return (
    <div className={s.wrapper}>
      {actions}
      <Filters
        showClearAll
        initialValues={filters}
        handleApply={onFiltersSubmit}
        overlay={
          <>
            <FilterCollapse items={filterItems} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="status">{t('common_status')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="status"
                options={[
                  { label: t('thank_you_status_approved'), value: THANK_YOU_STATUS_ENUM.APPROVED },
                  { label: t('thank_you_status_declined'), value: THANK_YOU_STATUS_ENUM.DECLINED },
                  { label: t('thank_you_status_pending'), value: THANK_YOU_STATUS_ENUM.PENDING },
                ]}
              />
            </FilterCustomWrapper>
          </>
        }
        filtersCount={getFiltersCount<
          Pick<GetFeedbacksPropsType, 'authorEmployers' | 'recipientEmployers' | 'status'>
        >(filters)}
      />
    </div>
  );
};

export default ThankYouFilters;
