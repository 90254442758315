import { FC, useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Checkbox, Form, Input, Typography } from 'gazprom-ui-lib';

import { CInstanceType } from 'types/cInstances.types';

import s from './cInstanceForm.module.scss';
import {
  CInstanceFormResponse,
  C_INSTANCE_FORM_ENUM,
  C_INSTANCE_FORM_NAME,
  DEFAULT_PASSWORD_VALUE,
} from './cInstanceForm.utils';

interface CInstanceFormProps {
  onSubmit: (form: CInstanceFormResponse) => void;
  cInstance?: CInstanceType;
}

const CInstanceForm: FC<CInstanceFormProps> = (props) => {
  const { onSubmit, cInstance } = props;

  const [form] = Form.useForm();
  const name = Form.useWatch(C_INSTANCE_FORM_ENUM.NAME, form);

  useEffect(() => {
    if (cInstance) {
      form.setFieldsValue({
        [C_INSTANCE_FORM_ENUM.NAME]: cInstance.name,
        [C_INSTANCE_FORM_ENUM.URL]: cInstance.url,
        [C_INSTANCE_FORM_ENUM.LOGIN]: cInstance.login,
        [C_INSTANCE_FORM_ENUM.PASSWORD]: DEFAULT_PASSWORD_VALUE,
        [C_INSTANCE_FORM_ENUM.ACTIVE]: cInstance.active,
      });
    }
  }, [form, cInstance]);

  return (
    <Form className={s.wrapper} form={form} name={C_INSTANCE_FORM_NAME} onFinish={onSubmit}>
      <div>
        <div className={s.titleContainer}>
          <Typography.Text strong size="16">
            {t('common_name')}
          </Typography.Text>
          <Typography.Text size="12" weight="400" type="secondary">
            {t('common_symbol_count', {
              current: name?.length ?? 0,
              total: 80,
            })}
          </Typography.Text>
        </div>
        <Form.Item
          normalize={normalizeTextField}
          name={C_INSTANCE_FORM_ENUM.NAME}
          rules={[formRules.required, { max: 80 }]}>
          <Input placeholder={t('common_enter_text')} maxLength={80} />
        </Form.Item>
      </div>

      <div>
        <Typography.Text strong size="16">
          {t('common_url')}
        </Typography.Text>
        <Form.Item
          normalize={normalizeTextField}
          name={C_INSTANCE_FORM_ENUM.URL}
          rules={[formRules.required]}>
          <Input placeholder={t('common_url_placeholder')} />
        </Form.Item>
      </div>
      <div>
        <Typography.Text strong size="16">
          {t('common_login')}
        </Typography.Text>
        <Form.Item
          normalize={normalizeTextField}
          name={C_INSTANCE_FORM_ENUM.LOGIN}
          rules={[formRules.required]}>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      </div>
      <div>
        <Typography.Text strong size="16">
          {t('common_password')}
        </Typography.Text>
        <Form.Item
          normalize={normalizeTextField}
          name={C_INSTANCE_FORM_ENUM.PASSWORD}
          rules={[formRules.required]}>
          <Input.Password placeholder={t('common_enter_text')} />
        </Form.Item>
      </div>
      <Form.Item name={C_INSTANCE_FORM_ENUM.ACTIVE} valuePropName="checked">
        <Checkbox>
          <Typography.Text size="14">{t('1c_schedule_synchronization')}</Typography.Text>
        </Checkbox>
      </Form.Item>
    </Form>
  );
};

export default CInstanceForm;
