import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_STATEMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { StatementType } from 'types/documents.types';

import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, FormResponseType } from './documentsStatements.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsStatements = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm<FormResponseType>();

  const { data, isFetching } = useGetData({ form });

  const columns = useColumns();

  const handleOnRow = (record: StatementType) => ({
    onClick: () => navigate(`${DOCUMENTS_STATEMENTS_ROUTE}/${record.id}`),
  });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('common_statements')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<StatementType, FormResponseType>
          scroll={{ y: 800, x: 700 }}
          dataSource={data?.items}
          columns={columns}
          rowKey="id"
          onRow={handleOnRow}
          total={data?.totalCount}
          loading={isFetching}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsStatements;
