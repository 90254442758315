import { useState } from 'react';

import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import { Form, Typography } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';
import { FORM_IMAGE_ENUM, FormImageResponse } from 'containers/image-modal/imageModal.utils';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';
import s from './HandbookBaseFormMemo.module.scss';

const HandbookBaseFormMemo = () => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const form = Form.useFormInstance();
  const cover = Form.useWatch(HANDBOOK_BASE_FORM_ENUM.MEMO);

  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };
  const onImgUpload = (_: string, data: FormImageResponse) => {
    form.setFieldValue(HANDBOOK_BASE_FORM_ENUM.MEMO, {
      ...data[FORM_IMAGE_ENUM.FULL_INFO],
      link: data[FORM_IMAGE_ENUM.FULL_INFO]?.url ?? data[FORM_IMAGE_ENUM.URL],
    });
    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([HANDBOOK_BASE_FORM_ENUM.MEMO]);
  };
  const coverSize = cover?.size ? (Number(cover.size) / (1024 * 1024)).toFixed(2) : 0;

  return (
    <>
      <Typography.Text strong size="16">
        {t('guide_handbook_memo')}
      </Typography.Text>
      <Form.Item name={HANDBOOK_BASE_FORM_ENUM.MEMO}>
        {cover ? (
          <ImgPreview
            iconName="docTypePdf"
            className={s.preview}
            resetImage={resetImage}
            showDownload
            type="icon"
            url={createMediaDownloadUrl(cover?.link)}
            title={cover?.name ?? createMediaDownloadUrl(cover?.link)}
            description={t('common_mb', { count: coverSize })}
          />
        ) : (
          <UploadImitation
            title={t('common_upload_a_file')}
            handleClick={handleModalOpenClose}
            description={t('validation_upload_a_doc_restrictions')}
          />
        )}
      </Form.Item>
      <ImageModal
        isOpen={showUploadModal}
        onSubmit={onImgUpload}
        onClose={handleModalOpenClose}
        uploadTitle={t('common_upload_a_file')}
        uploadDescription={t('validation_upload_a_doc_restrictions')}
        availableTypes={AVAILABLE_TYPES}
      />
    </>
  );
};

const AVAILABLE_TYPES = ['application/pdf', 'application/msword'];

export default HandbookBaseFormMemo;
