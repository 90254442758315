import { FC, PropsWithChildren } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import s from './OptionalLabel.module.scss';

const OptionalLabel: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <div className={s.optionalLabel}>
      {children}
      <Typography.Text size="12" type="secondary">
        {t('common_optional')}
      </Typography.Text>
    </div>
  );
};

export default OptionalLabel;
