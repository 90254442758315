import { t } from 'tools/i18n';

import { ColumnsType, Typography } from 'gazprom-ui-lib';

import { RenewTermType } from 'types/topManagement.types';

import s from './Table.module.scss';

const useColumns = (): ColumnsType<RenewTermType> => {
  const positionsRenderer = (positions: string[]) => {
    return (
      <Typography.Text className={s.positionItems} size="12" weight="500" type="secondary">
        {positions.map((position) => position).join(', ')}
      </Typography.Text>
    );
  };

  return [
    {
      width: 300,
      title: t('common_organization'),
      dataIndex: 'employerName',
    },
    {
      title: t('common_staffNames'),
      dataIndex: 'positions',
      render: positionsRenderer,
    },
  ];
};

export default useColumns;
