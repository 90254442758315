import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { usePatchKedoReviewTaskMutation } from 'services/kedo-review-tasks/kedoReviewTasksApiService';

import { Button, Typography, handleRequest } from 'gazprom-ui-lib';

import { REVIEW_TASK_TYPES_ENUM } from 'types/review-task.types';

import s from './SettingsLnaEmploymentDetails.module.scss';
import ActiveLnaTable from './containers/active-lna-table';
import CommonModal from './containers/common-modal';
import CreateModal from './containers/create-modal';
import Header from './containers/header';
import InactiveLnaTable from './containers/inactive-lna-table';
import { LNA_MODAL_ENUM } from './settingsLnaEmploymentDetails.utils';
import useModalConfig from './useModalConfig.hook';

const SettingsLnaEmploymentDetails = () => {
  const { instanceId } = useParams<{ instanceId: string }>();
  const [patchKedoReviewTask, { isLoading: isPatchKedoReviewTaskLoading }] =
    usePatchKedoReviewTaskMutation();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const [modalData, setModalData] = useState<{
    type: LNA_MODAL_ENUM;
    id: string;
  } | null>(null);

  const handleExclude = () => {
    if (modalData)
      patchKedoReviewTask({ id: modalData.id, status: REVIEW_TASK_TYPES_ENUM.LNA_CANCELED }).then(
        handleRequest({
          onSuccess: () => setModalData(null),
        }),
      );
  };

  const handleDelete = () => {};

  const handleAssign = () => {
    if (modalData)
      patchKedoReviewTask({ id: modalData.id, status: REVIEW_TASK_TYPES_ENUM.LNA_VALID }).then(
        handleRequest({
          onSuccess: () => setModalData(null),
        }),
      );
  };

  const modalConfig = useModalConfig({
    type: modalData?.type,
    handleExclude,
    handleDelete,
    handleAssign,
  });

  const handleCreateModalOpenClose = () => {
    setIsCreateModalVisible(!isCreateModalVisible);
  };

  return (
    <>
      <Header />
      <div className={s.create}>
        <Button
          type="link"
          size="small"
          className={s.button}
          leftIcon="plus"
          onClick={handleCreateModalOpenClose}>
          {t('1c_lna_create_new')}
        </Button>
      </div>
      <ActiveLnaTable
        handleExclude={(id) => {
          setModalData({
            type: LNA_MODAL_ENUM.EXCLUDE,
            id,
          });
        }}
      />
      <div className={s.withPadding}>
        <Typography.Title level={4} className={s.title}>
          {t('1c_lna_inactive')}
        </Typography.Title>
      </div>
      <InactiveLnaTable
        handleAssign={(id) =>
          setModalData({
            type: LNA_MODAL_ENUM.ASSIGN,
            id,
          })
        }
        handleDelete={(id) =>
          setModalData({
            type: LNA_MODAL_ENUM.DELETE,
            id,
          })
        }
      />
      <CommonModal
        isVisible={!!modalData}
        handleClose={() => setModalData(null)}
        config={modalConfig}
        isLoading={isPatchKedoReviewTaskLoading}
      />
      <CreateModal
        instanceId={instanceId!}
        visible={isCreateModalVisible}
        handleClose={handleCreateModalOpenClose}
      />
    </>
  );
};

export default SettingsLnaEmploymentDetails;
