import { useState } from 'react';

import { t } from 'tools/i18n';

import { Icon, Input, InputProps } from 'gazprom-ui-lib';

import NotificationNewsletterTable from 'containers/notification-newsletter-table';

import { SENDER_ENUM } from 'types/notification.types';

import s from './NotificationSystem.module.scss';
import Header from './containers/header';

const NotificationSystem = () => {
  const [searchName, setSearchName] = useState<string>('');

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setSearchName(e.target.value);
  };

  return (
    <>
      <Header />
      <div className={s.content}>
        <div>
          <Input
            allowClear
            size="small"
            value={searchName}
            onChange={handleSearchChange}
            prefix={<Icon name="search" />}
            placeholder={t('common_search')}
          />
        </div>

        <NotificationNewsletterTable search={searchName} senderType={SENDER_ENUM.APP} />
      </div>
    </>
  );
};

export default NotificationSystem;
