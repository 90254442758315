import { FC } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetLeadersQuery } from 'services/1c/cApiService';
import { LeaderType } from 'services/1c/cApiService.types';

import { Typography } from 'gazprom-ui-lib';

import DocumentsViewPerson from '../documents-view-person';
import s from './DocumentsViewParticipants.module.scss';

const DocumentsViewParticipants: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetLeadersQuery(id!);

  const { firstLeader, functionalLeader, leaders } = data || {};

  const renderLeader = (data: LeaderType) => {
    return (
      <DocumentsViewPerson
        key={data.employeeId}
        firstName={data.person.firstName}
        lastName={data.person.lastName}
        position={data.position}
      />
    );
  };

  return (
    <>
      {firstLeader && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_first_leader')}</Typography.Title>
          {renderLeader(firstLeader)}
        </div>
      )}
      {!!leaders?.length && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_common_leaders')}</Typography.Title>
          {leaders.map(renderLeader)}
        </div>
      )}
      {functionalLeader && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_functional_leaders')}</Typography.Title>
          {renderLeader(functionalLeader)}
        </div>
      )}
    </>
  );
};

export default DocumentsViewParticipants;
