import { SORT_ENUM } from 'types/sort.types';

export type RequestBodyType = {
  page: number;
  size: number;
  order: SORT_ENUM;
  property: string | null;
  search: string;
};

export const DEFAULT_REQUEST_BODY: RequestBodyType = {
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: null,
  search: '',
};
