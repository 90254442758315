import { FC, Key, useState } from 'react';

import { t } from 'tools/i18n';

import { useRemoveFeedbacksMutation } from 'services/thank-you/thankYouApiService';

import {
  Button,
  ButtonProps,
  Checkbox,
  ModalDefault,
  Table,
  TablePaginationConfig,
  TableProps,
} from 'gazprom-ui-lib';

import DownloadProgressModal from 'components/download-progress-modal';
import TableSelectedActions from 'components/table/table-selected-actions';

import { TMappedFeedBack, TMappedFeedBacks } from 'types/thankYou.types';

import ThankYouRowModal from '../thank-you-row-modal';
import { RequestBody, ThankYouFiltersType } from '../thank-you.utils';
import s from './ThankYouTable.module.scss';
import { handleDownload } from './thankYouTable.utils';
import useColumns from './useColumns.hook';

interface ThankYouTableProps {
  dataSource: TMappedFeedBacks;
  paginationConfig: TablePaginationConfig;
  loading: boolean;
  onChange: TableProps<TMappedFeedBack>['onChange'];
  filters: ThankYouFiltersType & Pick<RequestBody, 'text'>;
}

enum MODALS {
  DOWNLOAD_ERROR = 'downloadError',
  DOWNLOAD_SUCCESS = 'downloadSuccess',
  REMOVE = 'remove',
  EMPTY = '',
}

const ThankYouTable: FC<ThankYouTableProps> = (props) => {
  const { dataSource, paginationConfig, loading, filters, onChange } = props;
  const [selectedRow, setSelectedRow] = useState<TMappedFeedBack | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [isDownloadingInProgress, setIsDownloadingInProgress] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<MODALS>(MODALS.EMPTY);

  const [removeFeedbacks] = useRemoveFeedbacksMutation();

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    if (isSelectedAll && newSelectedRowKeys.length < paginationConfig.defaultPageSize!) {
      setIsSelectedAll(false);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleRowClick = (record: TMappedFeedBack) => setSelectedRow(record);
  const handleCloseModal = () => setSelectedRow(null);
  const removeSelection = () => {
    setSelectedRowKeys([]);
    setIsSelectedAll(false);
  };
  const handleSelectAll = () => {
    setIsSelectedAll(!isSelectedAll);

    if (isSelectedAll) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(dataSource.map((row) => row.key));
    }
  };

  const hasSelected = selectedRowKeys.length > 0;
  const rowSelection: TableProps<TMappedFeedBack>['rowSelection'] = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnTitle: <Checkbox checked={isSelectedAll} onChange={handleSelectAll} />,
  };

  const closeModal = () => setIsModalOpen(MODALS.EMPTY);

  const handleDownloadClick = () => {
    setIsDownloadingInProgress(true);
    handleDownload(filters, isSelectedAll, selectedRowKeys)
      .then(() => closeModal())
      .catch(() => setIsModalOpen(MODALS.DOWNLOAD_ERROR))
      .finally(() => setIsDownloadingInProgress(false));
  };

  const handleDownloadRetry = () => {
    handleDownloadClick();
    closeModal();
  };

  const removeHandler = () => {
    removeFeedbacks({ ids: selectedRowKeys });
    setSelectedRowKeys([]);
    closeModal();
  };

  const buttons: ButtonProps[] = [
    {
      children: t('news_deselect'),
      leftIcon: 'delete',
      onClick: removeSelection,
    },
    {
      children: t('thank_you_download_report'),
      leftIcon: 'calendar',
      onClick: handleDownloadClick,
    },
    {
      children: t('common_remove'),
      danger: true,
      leftIcon: 'radar',
      onClick: () => setIsModalOpen(MODALS.REMOVE),
    },
  ];

  const columns = useColumns();

  return (
    <>
      {hasSelected && <TableSelectedActions buttons={buttons} />}
      <Table
        dataSource={dataSource}
        columns={columns as TMappedFeedBacks}
        pagination={paginationConfig}
        className={s.table}
        onChange={onChange}
        onRow={(record: TMappedFeedBack) => ({
          onClick: () => handleRowClick(record),
        })}
        rowSelection={rowSelection}
        loading={loading}
        rowKey="key"
        showSorterTooltip={false}
      />
      <DownloadProgressModal isOpen={isDownloadingInProgress} />
      {selectedRow && (
        <ThankYouRowModal
          isModalOpen={Boolean(selectedRow)}
          onCancel={handleCloseModal}
          data={selectedRow}
        />
      )}

      <ModalDefault open={isModalOpen === MODALS.REMOVE} closable width={320} onCancel={closeModal}>
        <ModalDefault.Icon name="binFilled" type="danger" />
        <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('than_you_remove_selected_feedbacks')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button type="primary" onClick={removeHandler}>
            {t('common_remove')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>

      <ModalDefault
        open={isModalOpen === MODALS.DOWNLOAD_SUCCESS}
        closable
        width={320}
        onCancel={closeModal}>
        <ModalDefault.Icon name="ok" type="success" />
        <ModalDefault.Title>{t('thank_you_download_successfully_title')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('thank_you_download_successfully_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button type="primary" onClick={closeModal}>
            {t('common_ok')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>

      <ModalDefault
        open={isModalOpen === MODALS.DOWNLOAD_ERROR}
        closable
        width={320}
        onCancel={closeModal}>
        <ModalDefault.Icon name="error" type="danger" />
        <ModalDefault.Title>{t('common_something_went_wrong')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('thank_you_download_error_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button type="secondary" onClick={handleDownloadRetry} leftIcon="refresh">
            {t('common_retry_again')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default ThankYouTable;
