import { useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import useLocalStorage from './useLocalStorage.hook';

const LAST_PATH = 'lastPath';

const useLastPathHook = (isAuthenticated: boolean) => {
  const firstRender = useRef(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [storageLastPath, setStorageLastPath] = useLocalStorage(LAST_PATH, '');

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (isAuthenticated) {
        if (storageLastPath) {
          setStorageLastPath('');
          navigate(storageLastPath);
        }
      } else if (
        location.pathname &&
        location.pathname !== '/' &&
        !location.pathname.includes('/login')
      ) {
        setStorageLastPath(location.pathname);
      }
    }
  }, [isAuthenticated, location, navigate, setStorageLastPath, storageLastPath]);
};

export default useLastPathHook;
