import { useEffect } from 'react';

import { useLazyGetFeedbacksQuery } from 'services/thank-you/thankYouApiService';
import {
  GetFeedbacksPropsType,
  GetFeedbacksResponseType,
} from 'services/thank-you/thankYouApiService.types';

import { RequestBody, ThankYouFiltersType, fieldsAssociationsForSort } from './thank-you.utils';

interface UseGetFeedbacksProps {
  debouncedRequestBody: RequestBody;
  filters: ThankYouFiltersType;
}

const useGetFeedbacks = (
  props: UseGetFeedbacksProps,
): {
  isLoading: boolean;
  isFetching: boolean;
  feedbacks?: GetFeedbacksResponseType;
} => {
  const { debouncedRequestBody, filters } = props;

  const [getFeedbacks, { data: feedbacks, isLoading, isFetching }] = useLazyGetFeedbacksQuery();

  useEffect(() => {
    const { order, page, property, size, text } = debouncedRequestBody;

    const preparedBody: GetFeedbacksPropsType = {
      ...filters,
      page,
      size,
    };

    if (property && order) {
      preparedBody.sort = [
        {
          order,
          property: fieldsAssociationsForSort[property],
        },
      ];
    }

    if (text) {
      preparedBody.text = text;
    }

    getFeedbacks(preparedBody);
  }, [debouncedRequestBody, filters, getFeedbacks]);

  return {
    feedbacks,
    isFetching,
    isLoading,
  };
};

export default useGetFeedbacks;
