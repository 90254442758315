import { FC } from 'react';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { DEFAULT_FORM_STATE } from './topManagementForms.utils';
import { FormResponseType } from './topManagementForms.utils';
import useRequest from './useRequest.hook';

const TopManagementForms: FC = () => {
  const [form] = Form.useForm<FormResponseType>();

  const { forms, isLoading, isFetching } = useRequest({ form });

  const loading = isLoading || isFetching;

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <Table totalCount={forms?.totalCount} dataSource={forms?.items} loading={loading} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default TopManagementForms;
