import { useNavigate } from 'react-router-dom';
import { SURVEYS_EVENT_ROUTE, SURVEYS_ONCE_ROUTE } from 'routes/surveys/list';

import {
  Table as LibTable,
  TableProps as LibTableProps,
  TablePaginationConfig,
} from 'gazprom-ui-lib';

import { SurveyType } from 'types/surveys.types';

import useMatchOnceRoute from '../useMatchOnceRoute';

interface Props {
  dataSource: LibTableProps<SurveyType>['dataSource'];
  paginationConfig: TablePaginationConfig;
  isLoading: boolean;
  columns: LibTableProps<SurveyType>['columns'];
  onChange: LibTableProps<SurveyType>['onChange'];
}

const Table = (props: Props) => {
  const isOnceSurveysRoute = useMatchOnceRoute();
  const { dataSource, paginationConfig, isLoading, columns, onChange } = props;

  const navigate = useNavigate();

  const handleOnRow = (rowData: SurveyType) => {
    const { id, anketologId, revisionId } = rowData;
    const currentSurveysRoute = isOnceSurveysRoute ? SURVEYS_ONCE_ROUTE : SURVEYS_EVENT_ROUTE;
    const surveyRoute = `${currentSurveysRoute}/${id}/${anketologId}/${revisionId}`;

    return {
      onClick: () => navigate(surveyRoute),
    };
  };

  return (
    <LibTable<SurveyType>
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      pagination={paginationConfig}
      onRow={handleOnRow}
      loading={isLoading}
      onChange={onChange}
    />
  );
};

export default Table;
